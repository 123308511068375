import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import * as Yup from 'yup'
import { FormikProps } from 'formik'
import dayjs from 'dayjs'

import { Icons } from '../../components/Icon'
import { layoutSampleDataMocks } from '../../layouts/LayoutSampleData/mocks'
import { SampleDataTraineeFormValues, SampleDataTraineeTemplateProps } from '../SampleDataTrainee'
import { returnSampleDataSteps } from '../../relay/DataSample/SampleDataSteps'
import { actions, selectors } from '../../redux'
import { router } from '../../router'
import { sampleDataRoutes } from '../../relay/DataSample/SampleDataRoutes'
import { SampleDataAllInfo } from '../../types/sampleDataAllInfo'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { ModalVariant } from '../../components/Modal/types'
import { shortUniversConverter } from '../../relay/DataSample/ShortUniversConverter'
import { SampleFlagGroupInput, SampleStatus, UserRole } from '../../graphql/generated/graphql'

export const usePropsSampleDataExhibitorRefTemplateProps = (
  title?: string
): SampleDataTraineeTemplateProps => {
  const sampleData = useSelector(selectors.samples.sampleData)
  const sampleDataAllInfo = useSelector(selectors.samples.sampleDataAllInfo)
  const user = useSelector(selectors.auth.user)
  const [isValid, setIsValid] = useState<boolean>(false)
  const ref = useRef<FormikProps<SampleDataTraineeFormValues>>(null)
  const layoutProps = useDefaultLayoutTemplateProps('exponents')
  const collection = useSelector(selectors.exhibitors.collection)
  const sample = useSelector(selectors.samples.sample)
  const config = useSelector(selectors.configuration.configurationInfo)
  const refresh = useSelector(selectors.auth.authByToken)
  const [isNoteOpen, setIsNoteOpen] = useState<boolean>(false)
  const [isPending, setIsPending] = useState<boolean>(true)
  const [marketplaceDateHoursLeft, setMarketplaceDateHoursLeft] = useState<string | undefined>(
    undefined
  )
  const [marketplaceDateDaysLeft, setMarketplaceDateDaysLeft] = useState<string | undefined>(
    undefined
  )
  const [baseRef, setBaseRef] = useState<string | undefined>(undefined)
  const [forceRefresh, setForceRefresh] = useState<boolean>(true)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { exposantId } = useParams<{ exposantId: string }>()
  const { sampleId } = useParams<{ sampleId: string }>()

  // Force loader to make reloading more smooth
  useEffect(() => {
    const timerId = setTimeout(() => {
      setForceRefresh(false)
    }, 2000)

    return () => clearTimeout(timerId)
  }, [])

  useEffect(() => {
    if (
      sample?.collection &&
      sample.collection.exhibitor &&
      sample.collection.exhibitor.ebmsId &&
      (!collection || collection?.id !== sample.collection.id)
    ) {
      dispatch(
        actions.exhibitors.exhibitorsCollectionServiceRequest({
          exhibitorEbmsId: sample.collection.exhibitor.ebmsId,
          exhibitionApiId: sample.collection.exhibitionApiId,
        })
      )
    }
  }, [collection, dispatch, sample?.collection])

  useEffect(() => {
    setBaseRef(
      `${collection?.exhibition.seasonCode}${
        collection?.exhibition.seasonYear
      }_${shortUniversConverter(
        collection?.exhibitor.universe.name
      ).toUpperCase()}_${collection?.exhibitor.name
        .replace(/&/g, '')
        .replace(/\s+/g, '_')
        .replace(/[.]/g, '')
        .toUpperCase()}`
    )
  }, [collection, dispatch, sample])

  const setFlagsItems = (data: any) => {
    const flagGroups: SampleFlagGroupInput[] = []
    if (data) {
      for (const key in data) {
        if (data[key] && data[key].items) {
          if (Array.isArray(data[key].items)) {
            const items = data[key]
            flagGroups.push({
              groupId: data[key].id,
              applyToCollection: data[key].applyToAllCollection,
              flagItems: items.items.map((flagItem: any) => ({
                itemId: flagItem.id,
                value: flagItem.value ? flagItem.value : null,
              })),
            })
          }
        }
      }
      return flagGroups
    }
  }

  const updateSample = useCallback(
    (data: SampleDataAllInfo, name: string) => {
      if (sampleId) {
        dispatch(
          actions.samples.sampleUpdateRequest({
            sampleId: sampleId,
            input: {
              flagGroups: setFlagsItems(data),
              name,
            },
          })
        )
      }
    },
    [sampleId, dispatch]
  )

  const isSkipableStep = useCallback(() => {
    // if (
    //   user?.role === UserRole.Admin ||
    //   user?.role === UserRole.SuperAdmin ||
    //   user?.role === UserRole.ModePv ||
    //   user?.role === UserRole.FashionExpert
    // ) {
    return true
    // } else {
    //   return false
    // }
  }, [])

  const changeBase = useCallback(
    (ref: string | undefined) => {
      if (ref === undefined) {
        return ''
      }

      const baseRef = `${collection?.exhibition.seasonCode}${
        collection?.exhibition.seasonYear
      }_${shortUniversConverter(
        collection?.exhibitor.universe.name
      ).toUpperCase()}_${collection?.exhibitor.name
        .replace(/&/g, '')
        .replace(/\s+/g, '_')
        .replace(/[.]/g, '')
        .toUpperCase()}`

      const chaineModifiee = ref.replace(new RegExp(`.*${baseRef}_`), '')
      setIsPending(false)

      if (chaineModifiee === 'ECHANTILLON' && user?.role === UserRole.InputOperator) {
        return ''
      }

      return chaineModifiee
    },
    [collection, user]
  )

  useEffect(() => {
    if (
      config &&
      sample &&
      sample.statusUpdatedAt &&
      sample.status === SampleStatus.SelectedOkShooting
    ) {
      const dateString = sample.statusUpdatedAt
      const dateObject = dayjs(dateString)
      const dateInXDays = dateObject.add(config?.noDaysBeforeSendingToMarketplace, 'day')
      const dateNow = dayjs()
      const differenceInHours = dateInXDays.diff(dateNow, 'hour')
      const differenceInDays = dateInXDays.diff(dateNow, 'day')
      if (differenceInDays >= 0) {
        setMarketplaceDateHoursLeft(differenceInHours.toString())
        setMarketplaceDateDaysLeft(differenceInDays.toString())
      }
    }
  }, [config, config?.noDaysBeforeSendingToMarketplace, dispatch, sample])

  return useMemo(() => {
    return {
      title: title,
      isPending: forceRefresh || isPending,
      layoutProps: {
        ...layoutSampleDataMocks.basic,
        steps: {
          steps: returnSampleDataSteps(sampleData, exposantId, sampleId),
        },
        bottomActions: {
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
            onClick: () => {
              const link = router(sampleDataRoutes(sampleData, 'prev'), {
                exposantId,
                sampleId,
                apiId: collection?.exhibition.apiId as string,
              })
              navigate(link)
            },
          },
          note:
            collection?.internalNotes && collection?.internalNotes.length !== 0
              ? {
                  rounded: true,
                  number: collection?.internalNotes
                    ? collection?.internalNotes.length.toString()
                    : '0',
                  onClick: () => {
                    setIsNoteOpen(true)
                  },
                }
              : undefined,
          next: {
            text: 'Suivant',
            disabled: forceRefresh || isPending ? true : isSkipableStep() ? false : !isValid,
            onClick: () => {
              ref.current?.submitForm()
            },
            rightIconProps: {
              icon: Icons.arrowRight,
              width: 18,
              height: 18,
            },
          },
          nextPlus: {
            text: 'Rafraichir',
            isPending: refresh.pending,
            disabled: refresh.pending,
            onClick: () => {
              dispatch(actions.auth.authByTokenRequest())
            },
            rightIconProps: {
              icon: Icons.link,
              width: 18,
              height: 18,
            },
          },
        },
        title: `${sampleData.exhibitorRef} #${sampleId}`,
        ...layoutProps,
        marketplaceWarning:
          marketplaceDateDaysLeft && marketplaceDateHoursLeft
            ? {
                number: `J - ${marketplaceDateDaysLeft}`,
                text: `Dépôt sur la Marketplace dans ${marketplaceDateHoursLeft} heures`,
                redBg: true,
              }
            : undefined,
      },
      notes: {
        title: "Note interne sur l'exposant",
        notes:
          collection && collection.internalNotes && collection.internalNotes.length !== 0
            ? collection.internalNotes.map((note) => ({
                text: note.body,
                author: {
                  fullName:
                    collection?.internalNotes.length !== 0
                      ? `par ${note.user.lastName} ${note.user.firstName}`
                      : '',
                  date:
                    collection?.internalNotes.length !== 0
                      ? `le ${dayjs(note.writtenAt).format('DD.MM.YYYY')}`
                      : '',
                },
              }))
            : [],
        tags: {
          newTag: collection?.exhibitor?.isNew,
          returnTag: collection?.samplesToReturn !== 0,
        },
        onClose: () => setIsNoteOpen(false),
      },
      noteModal: {
        onClose: () => setIsNoteOpen(false),
        isOpen: isNoteOpen,
        variant: ModalVariant.Small,
      },
      form: {
        formikForm: {
          innerRef: ref,
          initialValues: {
            name: collection && changeBase(sampleData.exhibitorRef),
          } as SampleDataTraineeFormValues,
          onSubmit: (values) => {
            if (collection) {
              const updatedSampleDataAllInfo: SampleDataAllInfo = {
                ...sampleDataAllInfo,
                exhibitorRef: values.name ? `${baseRef}_${changeBase(values.name)}` : '',
              }
              dispatch(
                actions.samples.setSampleData({
                  ...sampleData,
                  exhibitorRef: values.name ? `${baseRef}_${changeBase(values.name)}` : '',
                })
              )
              dispatch(actions.samples.setSampleDataAllInfo(updatedSampleDataAllInfo))
              const link = router(sampleDataRoutes(sampleData, 'next'), {
                exposantId,
                sampleId,
              })
              updateSample(
                updatedSampleDataAllInfo,
                values.name ? `${baseRef}_${changeBase(values.name)}` : ''
              )
              navigate(link)
            }
          },
          validateOnChange: true,
          validateOnMount: true,
          validationSchema: Yup.object().shape({
            name: Yup.string().required(),
          }),
          onContextUpdate: (context) => {
            setIsValid(context.isValid)
          },
        },
        name: {
          name: 'name',
        },
        labelRef: 'Nom du produit',
        exhibitorRef: baseRef,
        sampleId: `_${sampleId}_01`,
      },
      onClose: () => undefined,
      openImage: false,
    }
  }, [
    baseRef,
    collection,
    dispatch,
    changeBase,
    exposantId,
    forceRefresh,
    isNoteOpen,
    isPending,
    isSkipableStep,
    isValid,
    layoutProps,
    marketplaceDateDaysLeft,
    marketplaceDateHoursLeft,
    navigate,
    refresh.pending,
    sampleData,
    sampleDataAllInfo,
    sampleId,
    title,
    updateSample,
  ])
}
