import dayjs from 'dayjs'

import { SummaryProps } from '../../components/Summary'
import { FormFieldSwitchSize } from '../../components/form/fields/FormFieldSwitch/types'
import { UserFragmentFragment } from '../../graphql/generated/graphql'
import { SampleData } from '../../types/sampleData'
import { SampleDataAllInfo } from '../../types/sampleDataAllInfo'

export const fillSummary = (
  sampleData: SampleData,
  sampleDataAllInfo: SampleDataAllInfo,
  user: UserFragmentFragment
) => {
  let summary: SummaryProps
  const material = sampleDataAllInfo.material.items
  if (sampleData.routeType === 'Fabrics') {
    summary = {
      items: [
        {
          title: 'Catégorie',
          content: [
            {
              text: sampleData.category,
            },
          ],
          allCollection: sampleDataAllInfo.category.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Destination produit',
          content: [
            {
              text: Array.isArray(sampleData.productDestination)
                ? sampleData.productDestination.join(' - ')
                : sampleData.productDestination || '',
            },
          ],
          allCollection: sampleDataAllInfo.productDestination.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Motif',
          content: [
            {
              text: sampleData.pattern,
            },
          ],
          allCollection: sampleDataAllInfo.pattern.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Codes de performances',
          content: [
            {
              text: Array.isArray(sampleData.performanceCode)
                ? sampleData.performanceCode.join(' - ')
                : sampleData.performanceCode || '',
            },
          ],
          allCollection: sampleDataAllInfo.performanceCode.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Certifications',
          content: [
            {
              text: Array.isArray(sampleData.certifications)
                ? sampleData.certifications.join(' - ')
                : sampleData.certifications || '',
            },
          ],
          allCollection: sampleDataAllInfo.certifications.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Présélection PV',
          content: [
            {
              text: sampleData.mkpForum,
            },
          ],
          allCollection: sampleDataAllInfo.mkpForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Forum',
          content: [
            {
              text: sampleData.forum,
            },
          ],
          allCollection: sampleDataAllInfo.forum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Sous-forum',
          content: [
            {
              text: sampleData.subForum,
            },
          ],
          allCollection: sampleDataAllInfo.subForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Info shooting',
          content: [
            {
              text: Array.isArray(sampleData.shooting)
                ? sampleData.shooting.join(' - ')
                : sampleData.shooting || '',
            },
          ],
          allCollection: sampleDataAllInfo.shooting.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Caisse de rangement',
          content: [
            {
              text: sampleData.boxe,
            },
          ],
          allCollection: sampleDataAllInfo.boxe.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
      ],
      bottomNote: {
        enteredBy: `Saisie par ${user.lastName} ${user.firstName}`,
        date: dayjs().format('DD.MM.YYYY'),
      },
    }
    return summary
  }
  if (sampleData.routeType === 'FabricsMarketplace') {
    summary = {
      items: [
        {
          title: 'Etiquette',
          content: [
            {
              image:
                sampleData.label && sampleData.label.map((img) => ({ src: img, alt: 'etiquette' })),
            },
          ],
        },
        {
          title: 'Référence exposant',
          content: [
            {
              text: sampleData.exhibitorRef,
            },
          ],
        },
        {
          title: 'Composition',
          content: [
            {
              text:
                material &&
                material
                  .map((item) => `${item.name} ${item.value && item.value ? item.value + '%' : ''}`)
                  .join(' - '),
            },
          ],
          allCollection: sampleDataAllInfo.material.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Couleur',
          content: [
            {
              text: sampleData.color,
            },
          ],
          allCollection: sampleDataAllInfo.color.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Catégorie',
          content: [
            {
              text: sampleData.category,
            },
          ],
          allCollection: sampleDataAllInfo.category.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Destination produit',
          content: [
            {
              text: Array.isArray(sampleData.productDestination)
                ? sampleData.productDestination.join(' - ')
                : sampleData.productDestination || '',
            },
          ],
          allCollection: sampleDataAllInfo.productDestination.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Motif',
          content: [
            {
              text: sampleData.pattern,
            },
          ],
          allCollection: sampleDataAllInfo.pattern.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Codes de performances',
          content: [
            {
              text: Array.isArray(sampleData.performanceCode)
                ? sampleData.performanceCode.join(' - ')
                : sampleData.performanceCode || '',
            },
          ],
          allCollection: sampleDataAllInfo.performanceCode.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Certifications',
          content: [
            {
              text: Array.isArray(sampleData.certifications)
                ? sampleData.certifications.join(' - ')
                : sampleData.certifications || '',
            },
          ],
          allCollection: sampleDataAllInfo.certifications.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Présélection PV',
          content: [
            {
              text: sampleData.mkpForum,
            },
          ],
          allCollection: sampleDataAllInfo.mkpForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Forum',
          content: [
            {
              text: sampleData.forum,
            },
          ],
          allCollection: sampleDataAllInfo.forum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Sous-forum',
          content: [
            {
              text: sampleData.subForum,
            },
          ],
          allCollection: sampleDataAllInfo.subForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Info shooting',
          content: [
            {
              text: Array.isArray(sampleData.shooting)
                ? sampleData.shooting.join(' - ')
                : sampleData.shooting || '',
            },
          ],
          allCollection: sampleDataAllInfo.shooting.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Caisse de rangement',
          content: [
            {
              text: sampleData.boxe,
            },
          ],
          allCollection: sampleDataAllInfo.boxe.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
      ],
      bottomNote: {
        enteredBy: `Saisie par ${user.lastName} ${user.firstName}`,
        date: dayjs().format('DD.MM.YYYY'),
      },
    }
    return summary
  }
  if (sampleData.routeType === 'Leather') {
    summary = {
      items: [
        {
          title: 'Etiquette',
          content: [
            {
              image:
                sampleData.label && sampleData.label.map((img) => ({ src: img, alt: 'etiquette' })),
            },
          ],
        },
        {
          title: 'Référence exposant',
          content: [
            {
              text: sampleData.exhibitorRef,
            },
          ],
        },
        {
          title: 'Catégorie',
          content: [
            {
              text: sampleData.category,
            },
          ],
          allCollection: sampleDataAllInfo.category.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Destination produit',
          content: [
            {
              text: Array.isArray(sampleData.productDestination)
                ? sampleData.productDestination.join(' - ')
                : sampleData.productDestination || '',
            },
          ],
          allCollection: sampleDataAllInfo.productDestination.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Animal',
          content: [
            {
              text: Array.isArray(sampleData.animal)
                ? sampleData.animal.join(' - ')
                : sampleData.animal || '',
            },
          ],
          allCollection: sampleDataAllInfo.animal.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Type de tannage',
          content: [
            {
              text: Array.isArray(sampleData.tanningType)
                ? sampleData.tanningType.join(' - ')
                : sampleData.tanningType || '',
            },
          ],
          allCollection: sampleDataAllInfo.tanningType.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Aspect',
          content: [
            {
              text: Array.isArray(sampleData.aspect)
                ? sampleData.aspect.join(' - ')
                : sampleData.aspect || '',
            },
          ],
          allCollection: sampleDataAllInfo.aspect.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Codes de performances',
          content: [
            {
              text: Array.isArray(sampleData.performanceCode)
                ? sampleData.performanceCode.join(' - ')
                : sampleData.performanceCode || '',
            },
          ],
          allCollection: sampleDataAllInfo.performanceCode.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Certifications',
          content: [
            {
              text: Array.isArray(sampleData.certifications)
                ? sampleData.certifications.join(' - ')
                : sampleData.certifications || '',
            },
          ],
          allCollection: sampleDataAllInfo.certifications.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Forum',
          content: [
            {
              text: sampleData.forum,
            },
          ],
          allCollection: sampleDataAllInfo.forum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Sous-forum',
          content: [
            {
              text: sampleData.subForum,
            },
          ],
          allCollection: sampleDataAllInfo.subForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Présélection PV',
          content: [
            {
              text: sampleData.mkpForum,
            },
          ],
          allCollection: sampleDataAllInfo.mkpForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Info shooting',
          content: [
            {
              text: Array.isArray(sampleData.shooting)
                ? sampleData.shooting.join(' - ')
                : sampleData.shooting || '',
            },
          ],
          allCollection: sampleDataAllInfo.shooting.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Caisse de rangement',
          content: [
            {
              text: sampleData.boxe,
            },
          ],
          allCollection: sampleDataAllInfo.boxe.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
      ],
      bottomNote: {
        enteredBy: `Saisie par ${user.lastName} ${user.firstName}`,
        date: dayjs().format('DD.MM.YYYY'),
      },
    }
    return summary
  }
  if (sampleData.routeType === 'LeatherMarketplace') {
    summary = {
      items: [
        {
          title: 'Etiquette',
          content: [
            {
              image:
                sampleData.label && sampleData.label.map((img) => ({ src: img, alt: 'etiquette' })),
            },
          ],
        },
        {
          title: 'Référence exposant',
          content: [
            {
              text: sampleData.exhibitorRef,
            },
          ],
        },
        {
          title: 'Catégorie',
          content: [
            {
              text: sampleData.category,
            },
          ],
          allCollection: sampleDataAllInfo.category.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Destination produit',
          content: [
            {
              text: Array.isArray(sampleData.productDestination)
                ? sampleData.productDestination.join(' - ')
                : sampleData.productDestination || '',
            },
          ],
          allCollection: sampleDataAllInfo.productDestination.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Animal',
          content: [
            {
              text: Array.isArray(sampleData.animal)
                ? sampleData.animal.join(' - ')
                : sampleData.animal || '',
            },
          ],
          allCollection: sampleDataAllInfo.animal.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Type de tannage',
          content: [
            {
              text: Array.isArray(sampleData.tanningType)
                ? sampleData.tanningType.join(' - ')
                : sampleData.tanningType || '',
            },
          ],
          allCollection: sampleDataAllInfo.tanningType.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Aspect',
          content: [
            {
              text: Array.isArray(sampleData.aspect)
                ? sampleData.aspect.join(' - ')
                : sampleData.aspect || '',
            },
          ],
          allCollection: sampleDataAllInfo.aspect.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Codes de performances',
          content: [
            {
              text: Array.isArray(sampleData.performanceCode)
                ? sampleData.performanceCode.join(' - ')
                : sampleData.performanceCode || '',
            },
          ],
          allCollection: sampleDataAllInfo.performanceCode.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Certifications',
          content: [
            {
              text: Array.isArray(sampleData.certifications)
                ? sampleData.certifications.join(' - ')
                : sampleData.certifications || '',
            },
          ],
          allCollection: sampleDataAllInfo.certifications.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Forum',
          content: [
            {
              text: sampleData.forum,
            },
          ],
          allCollection: sampleDataAllInfo.forum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Sous-forum',
          content: [
            {
              text: sampleData.subForum,
            },
          ],
          allCollection: sampleDataAllInfo.subForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Présélection PV',
          content: [
            {
              text: sampleData.mkpForum,
            },
          ],
          allCollection: sampleDataAllInfo.mkpForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Info shooting',
          content: [
            {
              text: Array.isArray(sampleData.shooting)
                ? sampleData.shooting.join(' - ')
                : sampleData.shooting || '',
            },
          ],
          allCollection: sampleDataAllInfo.shooting.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Caisse de rangement',
          content: [
            {
              text: sampleData.boxe,
            },
          ],
          allCollection: sampleDataAllInfo.boxe.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
      ],
      bottomNote: {
        enteredBy: `Saisie par ${user.lastName} ${user.firstName}`,
        date: dayjs().format('DD.MM.YYYY'),
      },
    }
    return summary
  }
  if (sampleData.routeType === 'LeatherSpecial') {
    summary = {
      items: [
        {
          title: 'Etiquette',
          content: [
            {
              image:
                sampleData.label && sampleData.label.map((img) => ({ src: img, alt: 'etiquette' })),
            },
          ],
        },
        {
          title: 'Référence exposant',
          content: [
            {
              text: sampleData.exhibitorRef,
            },
          ],
        },
        {
          title: 'Catégorie',
          content: [
            {
              text: sampleData.category,
            },
          ],
          allCollection: sampleDataAllInfo.category.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Destination produit',
          content: [
            {
              text: Array.isArray(sampleData.productDestination)
                ? sampleData.productDestination.join(' - ')
                : sampleData.productDestination || '',
            },
          ],
          allCollection: sampleDataAllInfo.productDestination.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Motif',
          content: [
            {
              text: Array.isArray(sampleData.pattern)
                ? sampleData.pattern.join(' - ')
                : sampleData.pattern || '',
            },
          ],
          allCollection: sampleDataAllInfo.pattern.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Composition',
          content: [
            {
              text: Array.isArray(sampleData.material)
                ? sampleData.material.join(' - ')
                : sampleData.material || '',
            },
          ],
          allCollection: sampleDataAllInfo.material.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Codes de performances',
          content: [
            {
              text: Array.isArray(sampleData.performanceCode)
                ? sampleData.performanceCode.join(' - ')
                : sampleData.performanceCode || '',
            },
          ],
          allCollection: sampleDataAllInfo.performanceCode.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Certifications',
          content: [
            {
              text: Array.isArray(sampleData.certifications)
                ? sampleData.certifications.join(' - ')
                : sampleData.certifications || '',
            },
          ],
          allCollection: sampleDataAllInfo.certifications.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Forum',
          content: [
            {
              text: sampleData.forum,
            },
          ],
          allCollection: sampleDataAllInfo.forum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Sous-forum',
          content: [
            {
              text: sampleData.subForum,
            },
          ],
          allCollection: sampleDataAllInfo.subForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Présélection PV',
          content: [
            {
              text: sampleData.mkpForum,
            },
          ],
          allCollection: sampleDataAllInfo.mkpForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Info shooting',
          content: [
            {
              text: Array.isArray(sampleData.shooting)
                ? sampleData.shooting.join(' - ')
                : sampleData.shooting || '',
            },
          ],
          allCollection: sampleDataAllInfo.shooting.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Caisse de rangement',
          content: [
            {
              text: sampleData.boxe,
            },
          ],
          allCollection: sampleDataAllInfo.boxe.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
      ],
      bottomNote: {
        enteredBy: `Saisie par ${user.lastName} ${user.firstName}`,
        date: dayjs().format('DD.MM.YYYY'),
      },
    }
    return summary
  }
  if (sampleData.routeType === 'LeatherSpecialMarketplace') {
    summary = {
      items: [
        {
          title: 'Etiquette',
          content: [
            {
              image:
                sampleData.label && sampleData.label.map((img) => ({ src: img, alt: 'etiquette' })),
            },
          ],
        },
        {
          title: 'Référence exposant',
          content: [
            {
              text: sampleData.exhibitorRef,
            },
          ],
        },
        {
          title: 'Catégorie',
          content: [
            {
              text: sampleData.category,
            },
          ],
          allCollection: sampleDataAllInfo.category.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Destination produit',
          content: [
            {
              text: Array.isArray(sampleData.productDestination)
                ? sampleData.productDestination.join(' - ')
                : sampleData.productDestination || '',
            },
          ],
          allCollection: sampleDataAllInfo.productDestination.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Motif',
          content: [
            {
              text: Array.isArray(sampleData.pattern)
                ? sampleData.pattern.join(' - ')
                : sampleData.pattern || '',
            },
          ],
          allCollection: sampleDataAllInfo.pattern.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Composition',
          content: [
            {
              text: Array.isArray(sampleData.material)
                ? sampleData.material.join(' - ')
                : sampleData.material || '',
            },
          ],
          allCollection: sampleDataAllInfo.material.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Codes de performances',
          content: [
            {
              text: Array.isArray(sampleData.performanceCode)
                ? sampleData.performanceCode.join(' - ')
                : sampleData.performanceCode || '',
            },
          ],
          allCollection: sampleDataAllInfo.performanceCode.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Certifications',
          content: [
            {
              text: Array.isArray(sampleData.certifications)
                ? sampleData.certifications.join(' - ')
                : sampleData.certifications || '',
            },
          ],
          allCollection: sampleDataAllInfo.certifications.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Forum',
          content: [
            {
              text: sampleData.forum,
            },
          ],
          allCollection: sampleDataAllInfo.forum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Sous-forum',
          content: [
            {
              text: sampleData.subForum,
            },
          ],
          allCollection: sampleDataAllInfo.subForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Présélection PV',
          content: [
            {
              text: sampleData.mkpForum,
            },
          ],
          allCollection: sampleDataAllInfo.mkpForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Info shooting',
          content: [
            {
              text: Array.isArray(sampleData.shooting)
                ? sampleData.shooting.join(' - ')
                : sampleData.shooting || '',
            },
          ],
          allCollection: sampleDataAllInfo.shooting.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Caisse de rangement',
          content: [
            {
              text: sampleData.boxe,
            },
          ],
          allCollection: sampleDataAllInfo.boxe.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
      ],
      bottomNote: {
        enteredBy: `Saisie par ${user.lastName} ${user.firstName}`,
        date: dayjs().format('DD.MM.YYYY'),
      },
    }
    return summary
  }
  if (sampleData.routeType === 'Manufacturing Leather') {
    summary = {
      items: [
        {
          title: 'Catégorie',
          content: [
            {
              text: sampleData.category,
            },
          ],
          allCollection: sampleDataAllInfo.category.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Codes de performances',
          content: [
            {
              text: Array.isArray(sampleData.performanceCode)
                ? sampleData.performanceCode.join(' - ')
                : sampleData.performanceCode || '',
            },
          ],
          allCollection: sampleDataAllInfo.performanceCode.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Certifications',
          content: [
            {
              text: Array.isArray(sampleData.certifications)
                ? sampleData.certifications.join(' - ')
                : sampleData.certifications || '',
            },
          ],
          allCollection: sampleDataAllInfo.certifications.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Forum',
          content: [
            {
              text: sampleData.forum,
            },
          ],
          allCollection: sampleDataAllInfo.forum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Sous-forum',
          content: [
            {
              text: sampleData.subForum,
            },
          ],
          allCollection: sampleDataAllInfo.subForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Présélection PV',
          content: [
            {
              text: sampleData.mkpForum,
            },
          ],
          allCollection: sampleDataAllInfo.mkpForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
      ],
      bottomNote: {
        enteredBy: `Saisie par ${user.lastName} ${user.firstName}`,
        date: dayjs().format('DD.MM.YYYY'),
      },
    }
    return summary
  }
  if (sampleData.routeType === 'Manufacturing LeatherMarketplace') {
    summary = {
      items: [
        {
          title: 'Catégorie',
          content: [
            {
              text: sampleData.category,
            },
          ],
          allCollection: sampleDataAllInfo.category.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Codes de performances',
          content: [
            {
              text: Array.isArray(sampleData.performanceCode)
                ? sampleData.performanceCode.join(' - ')
                : sampleData.performanceCode || '',
            },
          ],
          allCollection: sampleDataAllInfo.performanceCode.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Certifications',
          content: [
            {
              text: Array.isArray(sampleData.certifications)
                ? sampleData.certifications.join(' - ')
                : sampleData.certifications || '',
            },
          ],
          allCollection: sampleDataAllInfo.certifications.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Forum',
          content: [
            {
              text: sampleData.forum,
            },
          ],
          allCollection: sampleDataAllInfo.forum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Sous-forum',
          content: [
            {
              text: sampleData.subForum,
            },
          ],
          allCollection: sampleDataAllInfo.subForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Présélection PV',
          content: [
            {
              text: sampleData.mkpForum,
            },
          ],
          allCollection: sampleDataAllInfo.mkpForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
      ],
      bottomNote: {
        enteredBy: `Saisie par ${user.lastName} ${user.firstName}`,
        date: dayjs().format('DD.MM.YYYY'),
      },
    }
    return summary
  }
  if (sampleData.routeType === 'Manufacturing') {
    summary = {
      items: [
        {
          title: 'Catégorie',
          content: [
            {
              text: sampleData.category,
            },
          ],
          allCollection: sampleDataAllInfo.category.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Codes de performances',
          content: [
            {
              text: Array.isArray(sampleData.performanceCode)
                ? sampleData.performanceCode.join(' - ')
                : sampleData.performanceCode || '',
            },
          ],
          allCollection: sampleDataAllInfo.performanceCode.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Certifications',
          content: [
            {
              text: Array.isArray(sampleData.certifications)
                ? sampleData.certifications.join(' - ')
                : sampleData.certifications || '',
            },
          ],
          allCollection: sampleDataAllInfo.certifications.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Forum',
          content: [
            {
              text: sampleData.forum,
            },
          ],
          allCollection: sampleDataAllInfo.forum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Sous-forum',
          content: [
            {
              text: sampleData.subForum,
            },
          ],
          allCollection: sampleDataAllInfo.subForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Présélection PV',
          content: [
            {
              text: sampleData.mkpForum,
            },
          ],
          allCollection: sampleDataAllInfo.mkpForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
      ],
      bottomNote: {
        enteredBy: `Saisie par ${user.lastName} ${user.firstName}`,
        date: dayjs().format('DD.MM.YYYY'),
      },
    }
    return summary
  }
  if (sampleData.routeType === 'ManufacturingMarketplace') {
    summary = {
      items: [
        {
          title: 'Etiquette',
          content: [
            {
              image:
                sampleData.label && sampleData.label.map((img) => ({ src: img, alt: 'etiquette' })),
            },
          ],
        },
        {
          title: 'Référence exposant',
          content: [
            {
              text: sampleData.exhibitorRef,
            },
          ],
        },
        {
          title: 'Type de produit',
          content: [
            {
              text: sampleData.manufacturingType,
            },
          ],
        },
        {
          title: 'Composition',
          content: [
            {
              text: Array.isArray(sampleData.material)
                ? sampleData.material.join(' - ')
                : sampleData.material || '',
            },
          ],
          allCollection: sampleDataAllInfo.material.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Catégorie',
          content: [
            {
              text: sampleData.category,
            },
          ],
          allCollection: sampleDataAllInfo.category.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Codes de performances',
          content: [
            {
              text: Array.isArray(sampleData.performanceCode)
                ? sampleData.performanceCode.join(' - ')
                : sampleData.performanceCode || '',
            },
          ],
          allCollection: sampleDataAllInfo.performanceCode.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Certifications',
          content: [
            {
              text: Array.isArray(sampleData.certifications)
                ? sampleData.certifications.join(' - ')
                : sampleData.certifications || '',
            },
          ],
          allCollection: sampleDataAllInfo.certifications.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Forum',
          content: [
            {
              text: sampleData.forum,
            },
          ],
          allCollection: sampleDataAllInfo.forum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Sous-forum',
          content: [
            {
              text: sampleData.subForum,
            },
          ],
          allCollection: sampleDataAllInfo.subForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Présélection PV',
          content: [
            {
              text: sampleData.mkpForum,
            },
          ],
          allCollection: sampleDataAllInfo.mkpForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
      ],
      bottomNote: {
        enteredBy: `Saisie par ${user.lastName} ${user.firstName}`,
        date: dayjs().format('DD.MM.YYYY'),
      },
    }
    return summary
  }
  if (sampleData.routeType === 'Yarns') {
    summary = {
      items: [
        {
          title: 'Catégorie',
          content: [
            {
              text: sampleData.category,
            },
          ],
          allCollection: sampleDataAllInfo.category.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Destination produit',
          content: [
            {
              text: Array.isArray(sampleData.productDestination)
                ? sampleData.productDestination.join(' - ')
                : sampleData.productDestination || '',
            },
          ],
          allCollection: sampleDataAllInfo.productDestination.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Unit Value',
          content: [
            {
              text: Array.isArray(sampleData.unitValue)
                ? sampleData.unitValue.join(' - ')
                : sampleData.unitValue || '',
            },
          ],
          allCollection: sampleDataAllInfo.unitValue.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Number End',
          content: [
            {
              text: Array.isArray(sampleData.numberEnd)
                ? sampleData.numberEnd.join(' - ')
                : sampleData.numberEnd || '',
            },
          ],
          allCollection: sampleDataAllInfo.numberEnd.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Aspect',
          content: [
            {
              text: Array.isArray(sampleData.aspect)
                ? sampleData.aspect.join(' - ')
                : sampleData.aspect || '',
            },
          ],
          allCollection: sampleDataAllInfo.aspect.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Technology Activity',
          content: [
            {
              text: Array.isArray(sampleData.technologyActivity)
                ? sampleData.technologyActivity.join(' - ')
                : sampleData.technologyActivity || '',
            },
          ],
          allCollection: sampleDataAllInfo.technologyActivity.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Codes de performances',
          content: [
            {
              text: Array.isArray(sampleData.performanceCode)
                ? sampleData.performanceCode.join(' - ')
                : sampleData.performanceCode || '',
            },
          ],
          allCollection: sampleDataAllInfo.performanceCode.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Certifications',
          content: [
            {
              text: Array.isArray(sampleData.certifications)
                ? sampleData.certifications.join(' - ')
                : sampleData.certifications || '',
            },
          ],
          allCollection: sampleDataAllInfo.certifications.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Présélection PV',
          content: [
            {
              text: sampleData.mkpForum,
            },
          ],
          allCollection: sampleDataAllInfo.mkpForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Forum',
          content: [
            {
              text: sampleData.forum,
            },
          ],
          allCollection: sampleDataAllInfo.forum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Sous-forum',
          content: [
            {
              text: sampleData.subForum,
            },
          ],
          allCollection: sampleDataAllInfo.subForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'INFO shooting',
          content: [
            {
              text: Array.isArray(sampleData.shooting)
                ? sampleData.shooting.join(' - ')
                : sampleData.shooting || '',
            },
          ],
          allCollection: sampleDataAllInfo.shooting.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Caisse de rangement',
          content: [
            {
              text: sampleData.boxe,
            },
          ],
          allCollection: sampleDataAllInfo.boxe.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
      ],
      bottomNote: {
        enteredBy: `Saisie par ${user.lastName} ${user.firstName}`,
        date: dayjs().format('DD.MM.YYYY'),
      },
    }
    return summary
  }
  if (sampleData.routeType === 'YarnsMarketplace') {
    summary = {
      items: [
        {
          title: 'Etiquette',
          content: [
            {
              image:
                sampleData.label && sampleData.label.map((img) => ({ src: img, alt: 'etiquette' })),
            },
          ],
        },
        {
          title: 'Référence exposant',
          content: [
            {
              text: sampleData.exhibitorRef,
            },
          ],
        },
        {
          title: 'Composition',
          content: [
            {
              text: Array.isArray(sampleData.material)
                ? sampleData.material.join(' - ')
                : sampleData.material || '',
            },
          ],
          allCollection: sampleDataAllInfo.material.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Couleur',
          content: [
            {
              text: sampleData.color,
            },
          ],
          allCollection: sampleDataAllInfo.color.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Catégorie',
          content: [
            {
              text: sampleData.category,
            },
          ],
          allCollection: sampleDataAllInfo.category.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Destination produit',
          content: [
            {
              text: Array.isArray(sampleData.productDestination)
                ? sampleData.productDestination.join(' - ')
                : sampleData.productDestination || '',
            },
          ],
          allCollection: sampleDataAllInfo.productDestination.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Unit Value',
          content: [
            {
              text: Array.isArray(sampleData.unitValue)
                ? sampleData.unitValue.join(' - ')
                : sampleData.unitValue || '',
            },
          ],
          allCollection: sampleDataAllInfo.unitValue.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Number End',
          content: [
            {
              text: Array.isArray(sampleData.numberEnd)
                ? sampleData.numberEnd.join(' - ')
                : sampleData.numberEnd || '',
            },
          ],
          allCollection: sampleDataAllInfo.numberEnd.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Aspect',
          content: [
            {
              text: Array.isArray(sampleData.aspect)
                ? sampleData.aspect.join(' - ')
                : sampleData.aspect || '',
            },
          ],
          allCollection: sampleDataAllInfo.aspect.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Technology Activity',
          content: [
            {
              text: Array.isArray(sampleData.technologyActivity)
                ? sampleData.technologyActivity.join(' - ')
                : sampleData.technologyActivity || '',
            },
          ],
          allCollection: sampleDataAllInfo.technologyActivity.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Codes de performances',
          content: [
            {
              text: Array.isArray(sampleData.performanceCode)
                ? sampleData.performanceCode.join(' - ')
                : sampleData.performanceCode || '',
            },
          ],
          allCollection: sampleDataAllInfo.performanceCode.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Certifications',
          content: [
            {
              text: Array.isArray(sampleData.certifications)
                ? sampleData.certifications.join(' - ')
                : sampleData.certifications || '',
            },
          ],
          allCollection: sampleDataAllInfo.certifications.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Présélection PV',
          content: [
            {
              text: sampleData.mkpForum,
            },
          ],
          allCollection: sampleDataAllInfo.mkpForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Forum',
          content: [
            {
              text: sampleData.forum,
            },
          ],
          allCollection: sampleDataAllInfo.forum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Sous-forum',
          content: [
            {
              text: sampleData.subForum,
            },
          ],
          allCollection: sampleDataAllInfo.subForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'INFO shooting',
          content: [
            {
              text: Array.isArray(sampleData.shooting)
                ? sampleData.shooting.join(' - ')
                : sampleData.shooting || '',
            },
          ],
          allCollection: sampleDataAllInfo.shooting.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Caisse de rangement',
          content: [
            {
              text: sampleData.boxe,
            },
          ],
          allCollection: sampleDataAllInfo.boxe.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
      ],
      bottomNote: {
        enteredBy: `Saisie par ${user.lastName} ${user.firstName}`,
        date: dayjs().format('DD.MM.YYYY'),
      },
    }
    return summary
  }
  if (sampleData.routeType === 'Designs') {
    summary = {
      items: [
        {
          title: 'Catégorie',
          content: [
            {
              text: sampleData.category,
            },
          ],
          allCollection: sampleDataAllInfo.category.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Motif',
          content: [
            {
              text: sampleData.pattern,
            },
          ],
          allCollection: sampleDataAllInfo.pattern.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Type de motif',
          content: [
            {
              text: Array.isArray(sampleData.patternType)
                ? sampleData.patternType.join(' - ')
                : sampleData.patternType || '',
            },
          ],
          allCollection: sampleDataAllInfo.patternType.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Forum',
          content: [
            {
              text: sampleData.forum,
            },
          ],
          allCollection: sampleDataAllInfo.forum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Sous-forum',
          content: [
            {
              text: sampleData.subForum,
            },
          ],
          allCollection: sampleDataAllInfo.subForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Présélection PV',
          content: [
            {
              text: sampleData.mkpForum,
            },
          ],
          allCollection: sampleDataAllInfo.mkpForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
      ],
      bottomNote: {
        enteredBy: `Saisie par ${user.lastName} ${user.firstName}`,
        date: dayjs().format('DD.MM.YYYY'),
      },
    }
    return summary
  }
  if (sampleData.routeType === 'DesignsMarketplace') {
    summary = {
      items: [
        {
          title: 'Etiquette',
          content: [
            {
              image:
                sampleData.label && sampleData.label.map((img) => ({ src: img, alt: 'etiquette' })),
            },
          ],
        },
        {
          title: 'Référence exposant',
          content: [
            {
              text: sampleData.exhibitorRef,
            },
          ],
        },
        {
          title: 'Catégorie',
          content: [
            {
              text: sampleData.category,
            },
          ],
          allCollection: sampleDataAllInfo.category.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Motif',
          content: [
            {
              text: sampleData.pattern,
            },
          ],
          allCollection: sampleDataAllInfo.pattern.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Type de motif',
          content: [
            {
              text: Array.isArray(sampleData.patternType)
                ? sampleData.patternType.join(' - ')
                : sampleData.patternType || '',
            },
          ],
          allCollection: sampleDataAllInfo.patternType.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Forum',
          content: [
            {
              text: sampleData.forum,
            },
          ],
          allCollection: sampleDataAllInfo.forum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Sous-forum',
          content: [
            {
              text: sampleData.subForum,
            },
          ],
          allCollection: sampleDataAllInfo.subForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Présélection PV',
          content: [
            {
              text: sampleData.mkpForum,
            },
          ],
          allCollection: sampleDataAllInfo.mkpForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
      ],
      bottomNote: {
        enteredBy: `Saisie par ${user.lastName} ${user.firstName}`,
        date: dayjs().format('DD.MM.YYYY'),
      },
    }
    return summary
  }
  if (sampleData.routeType === 'Accessories') {
    summary = {
      items: [
        {
          title: 'Etiquette',
          content: [
            {
              image:
                sampleData.label && sampleData.label.map((img) => ({ src: img, alt: 'etiquette' })),
            },
          ],
        },
        {
          title: 'Référence exposant',
          content: [
            {
              text: sampleData.exhibitorRef,
            },
          ],
        },
        {
          title: 'Catégorie',
          content: [
            {
              text: sampleData.category,
            },
          ],
          allCollection: sampleDataAllInfo.category.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Destination produit',
          content: [
            {
              text: Array.isArray(sampleData.productDestination)
                ? sampleData.productDestination.join(' - ')
                : sampleData.productDestination || '',
            },
          ],
          allCollection: sampleDataAllInfo.productDestination.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: "Type d'accessoire",
          content: [
            {
              text: Array.isArray(sampleData.accessorieType)
                ? sampleData.accessorieType.join(' - ')
                : sampleData.accessorieType || '',
            },
          ],
          allCollection: sampleDataAllInfo.accessorieType.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Composition',
          content: [
            {
              text: Array.isArray(sampleData.material)
                ? sampleData.material.join(' - ')
                : sampleData.material || '',
            },
          ],
          allCollection: sampleDataAllInfo.material.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Aspect',
          content: [
            {
              text: Array.isArray(sampleData.aspect)
                ? sampleData.aspect.join(' - ')
                : sampleData.aspect || '',
            },
          ],
          allCollection: sampleDataAllInfo.aspect.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },

        {
          title: 'Codes de performance',
          content: [
            {
              text: Array.isArray(sampleData.performanceCode)
                ? sampleData.performanceCode.join(' - ')
                : sampleData.performanceCode || '',
            },
          ],
          allCollection: sampleDataAllInfo.performanceCode.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Certifications',
          content: [
            {
              text: Array.isArray(sampleData.certifications)
                ? sampleData.certifications.join(' - ')
                : sampleData.certifications || '',
            },
          ],
          allCollection: sampleDataAllInfo.certifications.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Forum',
          content: [
            {
              text: sampleData.forum,
            },
          ],
          allCollection: sampleDataAllInfo.forum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Sous-forum',
          content: [
            {
              text: sampleData.subForum,
            },
          ],
          allCollection: sampleDataAllInfo.subForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Présélection PV',
          content: [
            {
              text: sampleData.mkpForum,
            },
          ],
          allCollection: sampleDataAllInfo.mkpForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'INFO shooting',
          content: [
            {
              text: Array.isArray(sampleData.shooting)
                ? sampleData.shooting.join(' - ')
                : sampleData.shooting || '',
            },
          ],
          allCollection: sampleDataAllInfo.shooting.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Caisse de rangement',
          content: [
            {
              text: sampleData.boxe,
            },
          ],
          allCollection: sampleDataAllInfo.boxe.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
      ],
      bottomNote: {
        enteredBy: `Saisie par ${user.lastName} ${user.firstName}`,
        date: dayjs().format('DD.MM.YYYY'),
      },
    }
    return summary
  }
  if (sampleData.routeType === 'AccessoriesMarketplace') {
    summary = {
      items: [
        {
          title: 'Etiquette',
          content: [
            {
              image:
                sampleData.label && sampleData.label.map((img) => ({ src: img, alt: 'etiquette' })),
            },
          ],
        },
        {
          title: 'Référence exposant',
          content: [
            {
              text: sampleData.exhibitorRef,
            },
          ],
        },
        {
          title: 'Catégorie',
          content: [
            {
              text: sampleData.category,
            },
          ],
          allCollection: sampleDataAllInfo.category.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Destination produit',
          content: [
            {
              text: Array.isArray(sampleData.productDestination)
                ? sampleData.productDestination.join(' - ')
                : sampleData.productDestination || '',
            },
          ],
          allCollection: sampleDataAllInfo.productDestination.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: "Type d'accessoire",
          content: [
            {
              text: Array.isArray(sampleData.accessorieType)
                ? sampleData.accessorieType.join(' - ')
                : sampleData.accessorieType || '',
            },
          ],
          allCollection: sampleDataAllInfo.accessorieType.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Composition',
          content: [
            {
              text: Array.isArray(sampleData.material)
                ? sampleData.material.join(' - ')
                : sampleData.material || '',
            },
          ],
          allCollection: sampleDataAllInfo.material.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Aspect',
          content: [
            {
              text: Array.isArray(sampleData.aspect)
                ? sampleData.aspect.join(' - ')
                : sampleData.aspect || '',
            },
          ],
          allCollection: sampleDataAllInfo.aspect.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },

        {
          title: 'Codes de performance',
          content: [
            {
              text: Array.isArray(sampleData.performanceCode)
                ? sampleData.performanceCode.join(' - ')
                : sampleData.performanceCode || '',
            },
          ],
          allCollection: sampleDataAllInfo.performanceCode.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Certifications',
          content: [
            {
              text: Array.isArray(sampleData.certifications)
                ? sampleData.certifications.join(' - ')
                : sampleData.certifications || '',
            },
          ],
          allCollection: sampleDataAllInfo.certifications.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Forum',
          content: [
            {
              text: sampleData.forum,
            },
          ],
          allCollection: sampleDataAllInfo.forum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Sous-forum',
          content: [
            {
              text: sampleData.subForum,
            },
          ],
          allCollection: sampleDataAllInfo.subForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Présélection PV',
          content: [
            {
              text: sampleData.mkpForum,
            },
          ],
          allCollection: sampleDataAllInfo.mkpForum.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'INFO shooting',
          content: [
            {
              text: Array.isArray(sampleData.shooting)
                ? sampleData.shooting.join(' - ')
                : sampleData.shooting || '',
            },
          ],
          allCollection: sampleDataAllInfo.shooting.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Caisse de rangement',
          content: [
            {
              text: sampleData.boxe,
            },
          ],
          allCollection: sampleDataAllInfo.boxe.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
      ],
      bottomNote: {
        enteredBy: `Saisie par ${user.lastName} ${user.firstName}`,
        date: dayjs().format('DD.MM.YYYY'),
      },
    }
    return summary
  }
  if (sampleData.routeType === 'Trainee') {
    summary = {
      items: [
        {
          title: 'Etiquette',
          content: [
            {
              image:
                sampleData.label && sampleData.label.map((img) => ({ src: img, alt: 'etiquette' })),
            },
          ],
        },
        {
          title: 'Référence exposant',
          content: [
            {
              text: sampleData.exhibitorRef,
            },
          ],
        },
        {
          title: 'Composition',
          content: [
            {
              text: Array.isArray(sampleData.material)
                ? sampleData.material.join(' - ')
                : sampleData.material || '',
            },
          ],
          allCollection: sampleDataAllInfo.material.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
        {
          title: 'Couleur',
          content: [
            {
              text: sampleData.color,
            },
          ],
          allCollection: sampleDataAllInfo.color.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
      ],
      bottomNote: {
        enteredBy: `Saisie par ${user.lastName} ${user.firstName}`,
        date: dayjs().format('DD.MM.YYYY'),
      },
    }
    return summary
  }
  if (sampleData.routeType === 'TraineeDesign') {
    summary = {
      items: [
        {
          title: 'Référence exposant',
          content: [
            {
              text: sampleData.exhibitorRef,
            },
          ],
        },
      ],
      bottomNote: {
        enteredBy: `Saisie par ${user.lastName} ${user.firstName}`,
        date: dayjs().format('DD.MM.YYYY'),
      },
    }
    return summary
  }
  if (sampleData.routeType === 'TraineeManuf') {
    summary = {
      items: [
        {
          title: 'Etiquette',
          content: [
            {
              image:
                sampleData.label && sampleData.label.map((img) => ({ src: img, alt: 'etiquette' })),
            },
          ],
        },
        {
          title: 'Référence exposant',
          content: [
            {
              text: sampleData.exhibitorRef,
            },
          ],
        },
        {
          title: 'Type de produit',
          content: [
            {
              text: sampleData.manufacturingType,
            },
          ],
        },
        {
          title: 'Composition',
          content: [
            {
              text: Array.isArray(sampleData.material)
                ? sampleData.material.join(' - ')
                : sampleData.material || '',
            },
          ],
          allCollection: sampleDataAllInfo.material.applyToAllCollection
            ? {
                name: 'allCollection',
                inlineLabel: 'Appliquer à toute la collection',
                variantSize: FormFieldSwitchSize.Small,
                value: true,
              }
            : undefined,
        },
      ],
      bottomNote: {
        enteredBy: `Saisie par ${user.lastName} ${user.firstName}`,
        date: dayjs().format('DD.MM.YYYY'),
      },
    }
    return summary
  }
  if (sampleData.routeType === 'TraineeShort') {
    summary = {
      items: [
        {
          title: 'Etiquette',
          content: [
            {
              image:
                sampleData.label && sampleData.label.map((img) => ({ src: img, alt: 'etiquette' })),
            },
          ],
        },
        {
          title: 'Référence exposant',
          content: [
            {
              text: sampleData.exhibitorRef,
            },
          ],
        },
      ],
      bottomNote: {
        enteredBy: `Saisie par ${user.lastName} ${user.firstName}`,
        date: dayjs().format('DD/MM/YYYY'),
      },
    }
    return summary
  }
}
