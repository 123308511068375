import { router, routesPath } from '../../router'
import { SampleData } from '../../types/sampleData'

export const returnSampleDataSteps = (
  sampleData: SampleData,
  exposantId?: string,
  sampleId?: string
) => {
  const currentURL = window.location.href
  const lastSegment = currentURL.substring(currentURL.lastIndexOf('/') + 1)
  if (sampleData.routeType === 'Fabrics') {
    const stepsFabrics = [
      {
        isCurrent: lastSegment === 'category',
        value: '1. Catégories',
        resume: [sampleData.category ? sampleData.category : ''],
        href: sampleData.category
          ? router(routesPath.exponentSampleDataCategory, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'forum',
        value: '2. Forum',
        resume: [sampleData.forum ? sampleData.forum : ''],
        href: sampleData.forum
          ? router(routesPath.exponentSampleDataForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'subForum',
        value: '3. Sous-forum',
        resume: [sampleData.subForum ? sampleData.subForum : ''],
        href: sampleData.subForum
          ? router(routesPath.exponentSampleDataSubForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'exhibitorRef',
        value: '4. Référence',
        resume: [sampleData.exhibitorRef ? sampleData.exhibitorRef : ''],
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataExhibitorRef, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'productDestination',
        value: '5. Destination produit',
        resume: [
          Array.isArray(sampleData.productDestination)
            ? sampleData.productDestination.join(' - ')
            : sampleData.productDestination || '',
        ],
        href: sampleData.productDestination
          ? router(routesPath.exponentSampleDataProductDestination, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'pattern',
        value: '6. Motif',
        resume: [sampleData.pattern ? sampleData.pattern : ''],
        href: sampleData.pattern
          ? router(routesPath.exponentSampleDataPattern, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'performanceCode',
        value: '7. Codes de performances',
        resume: [
          Array.isArray(sampleData.performanceCode)
            ? sampleData.performanceCode.join(' - ')
            : sampleData.performanceCode || '',
        ],
        href: sampleData.performanceCode
          ? router(routesPath.exponentSampleDataPerformanceCode, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'certifications',
        value: '8. Certifications',
        resume: [
          Array.isArray(sampleData.certifications)
            ? sampleData.certifications.join(' - ')
            : sampleData.certifications || '',
        ],
        href: sampleData.certifications
          ? router(routesPath.exponentSampleDataCertifications, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'mkpForum',
        value: '9. Présélection PV',
        resume: [sampleData.mkpForum ? sampleData.mkpForum : ''],
        href: sampleData.mkpForum
          ? router(routesPath.exponentSampleDataMkpForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'shooting',
        value: '10. Info shooting',
        resume: [
          Array.isArray(sampleData.shooting)
            ? sampleData.shooting.join(' - ')
            : sampleData.shooting || '',
        ],
        href: sampleData.shooting
          ? router(routesPath.exponentSampleDataShooting, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'boxe',
        value: '11. Caisse de rangement',
        resume: [sampleData.boxe ? sampleData.boxe : ''],
        href: sampleData.boxe
          ? router(routesPath.exponentSampleDataBoxes, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'summary',
        value: '12. Récapitulatif',
        href: sampleData.boxe
          ? router(routesPath.exponentSampleDataBoxes, { exposantId, sampleId })
          : undefined,
      },
    ]
    return stepsFabrics
  }

  if (sampleData.routeType === 'FabricsMarketplace') {
    const stepsFabricsMarketplace = [
      {
        isCurrent: lastSegment === 'category',
        value: '1. Catégories',
        resume: [sampleData.category ? sampleData.category : ''],
        href: sampleData.category
          ? router(routesPath.exponentSampleDataCategory, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'forum',
        value: '2. Forum',
        resume: [sampleData.forum ? sampleData.forum : ''],
        href: sampleData.forum
          ? router(routesPath.exponentSampleDataForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'subForum',
        value: '3. Sous-forum',
        resume: [sampleData.subForum ? sampleData.subForum : ''],
        href: sampleData.subForum
          ? router(routesPath.exponentSampleDataSubForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'exhibitorRef',
        value: '4. Référence',
        resume: [sampleData.exhibitorRef ? sampleData.exhibitorRef : ''],
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataExhibitorRef, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'material',
        value: '5. Composition',
        resume: [
          Array.isArray(sampleData.material)
            ? sampleData.material.join(' - ')
            : sampleData.material || '',
        ],
        href: sampleData.material
          ? router(routesPath.exponentSampleDataMaterial, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'productDestination',
        value: '6. Destination produit',
        resume: [
          Array.isArray(sampleData.productDestination)
            ? sampleData.productDestination.join(' - ')
            : sampleData.productDestination || '',
        ],
        href: sampleData.productDestination
          ? router(routesPath.exponentSampleDataProductDestination, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'pattern',
        value: '7. Motif',
        resume: [sampleData.pattern ? sampleData.pattern : ''],
        href: sampleData.pattern
          ? router(routesPath.exponentSampleDataPattern, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'performanceCode',
        value: '8. Codes de performances',
        resume: [
          Array.isArray(sampleData.performanceCode)
            ? sampleData.performanceCode.join(' - ')
            : sampleData.performanceCode || '',
        ],
        href: sampleData.performanceCode
          ? router(routesPath.exponentSampleDataPerformanceCode, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'certifications',
        value: '9. Certifications',
        resume: [
          Array.isArray(sampleData.certifications)
            ? sampleData.certifications.join(' - ')
            : sampleData.certifications || '',
        ],
        href: sampleData.certifications
          ? router(routesPath.exponentSampleDataCertifications, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'mkpForum',
        value: '10. Présélection PV',
        resume: [sampleData.mkpForum ? sampleData.mkpForum : ''],
        href: sampleData.mkpForum
          ? router(routesPath.exponentSampleDataMkpForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'shooting',
        value: '11. Info shooting',
        resume: [
          Array.isArray(sampleData.shooting)
            ? sampleData.shooting.join(' - ')
            : sampleData.shooting || '',
        ],
        href: sampleData.shooting
          ? router(routesPath.exponentSampleDataShooting, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'boxe',
        value: '12. Caisse de rangement',
        resume: [sampleData.boxe ? sampleData.boxe : ''],
        href: sampleData.boxe
          ? router(routesPath.exponentSampleDataBoxes, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'label',
        value: '13. Etiquette',
        resume: [sampleData.label ? 'Photo' : ''],
        href: sampleData.label
          ? router(routesPath.exponentSampleDataLabel, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'color',
        value: '14. Couleur',
        resume: [sampleData.color ? sampleData.color : ''],
        href: sampleData.color
          ? router(routesPath.exponentSampleDataColor, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'summary',
        value: '15. Récapitulatif',
        href: sampleData.boxe
          ? router(routesPath.exponentSampleDataSummary, { exposantId, sampleId })
          : undefined,
      },
    ]
    return stepsFabricsMarketplace
  }
  if (sampleData.routeType === 'Leather') {
    const stepsLeather = [
      {
        isCurrent: lastSegment === 'category',
        value: '1. Catégories',
        resume: [sampleData.category ? sampleData.category : ''],
        href: sampleData.category
          ? router(routesPath.exponentSampleDataCategory, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'forum',
        value: '2. Forum',
        resume: [sampleData.forum ? sampleData.forum : ''],
        href: sampleData.forum
          ? router(routesPath.exponentSampleDataForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'subForum',
        value: '3. Sous-forum',
        resume: [sampleData.subForum ? sampleData.subForum : ''],
        href: sampleData.subForum
          ? router(routesPath.exponentSampleDataSubForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'exhibitorRef',
        value: '4. Référence exposant',
        resume: [sampleData.exhibitorRef ? sampleData.exhibitorRef : ''],
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataExhibitorRef, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'productDestination',
        value: '5. Destination produit',
        resume: [
          Array.isArray(sampleData.productDestination)
            ? sampleData.productDestination.join(' - ')
            : sampleData.productDestination || '',
        ],
        href: sampleData.productDestination
          ? router(routesPath.exponentSampleDataProductDestination, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'animal',
        value: '6. Animal',
        resume: [
          Array.isArray(sampleData.animal)
            ? sampleData.animal.join(' - ')
            : sampleData.animal || '',
        ],
        href: sampleData.animal
          ? router(routesPath.exponentSampleDataAnimal, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'tanningType',
        value: '7. Type de tannage',
        resume: [
          Array.isArray(sampleData.tanningType)
            ? sampleData.tanningType.join(' - ')
            : sampleData.tanningType || '',
        ],
        href: sampleData.tanningType
          ? router(routesPath.exponentSampleDataTanningType, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'aspect',
        value: '8. Aspect',
        resume: [
          Array.isArray(sampleData.aspect)
            ? sampleData.aspect.join(' - ')
            : sampleData.aspect || '',
        ],
        href: sampleData.aspect
          ? router(routesPath.exponentSampleDataAspect, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'performanceCode',
        value: '9. Codes de performances',
        resume: [
          Array.isArray(sampleData.performanceCode)
            ? sampleData.performanceCode.join(' - ')
            : sampleData.performanceCode || '',
        ],
        href: sampleData.performanceCode
          ? router(routesPath.exponentSampleDataPerformanceCode, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'certifications',
        value: '10. Certifications',
        resume: [
          Array.isArray(sampleData.certifications)
            ? sampleData.certifications.join(' - ')
            : sampleData.certifications || '',
        ],
        href: sampleData.certifications
          ? router(routesPath.exponentSampleDataCertifications, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'mkpForum',
        value: '11. Présélection PV',
        resume: [sampleData.mkpForum ? sampleData.mkpForum : ''],
        href: sampleData.mkpForum
          ? router(routesPath.exponentSampleDataMkpForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'shooting',
        value: '12. INFO shooting',
        resume: [
          Array.isArray(sampleData.shooting)
            ? sampleData.shooting.join(' - ')
            : sampleData.shooting || '',
        ],
        href: sampleData.shooting
          ? router(routesPath.exponentSampleDataShooting, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'boxe',
        value: '13. Caisse de rangement',
        resume: [sampleData.boxe ? sampleData.boxe : ''],
        href: sampleData.boxe
          ? router(routesPath.exponentSampleDataBoxes, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'label',
        value: '14. Etiquette',
        resume: [sampleData.label ? 'Photo' : ''],
        href: sampleData.label
          ? router(routesPath.exponentSampleDataLabel, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'summary',
        value: '15. Récapitulatif',
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataSummary, { exposantId, sampleId })
          : undefined,
      },
    ]
    return stepsLeather
  }
  if (sampleData.routeType === 'LeatherMarketplace') {
    const stepsLeather = [
      {
        isCurrent: lastSegment === 'category',
        value: '1. Catégories',
        resume: [sampleData.category ? sampleData.category : ''],
        href: sampleData.category
          ? router(routesPath.exponentSampleDataCategory, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'forum',
        value: '2. Forum',
        resume: [sampleData.forum ? sampleData.forum : ''],
        href: sampleData.forum
          ? router(routesPath.exponentSampleDataForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'subForum',
        value: '3. Sous-forum',
        resume: [sampleData.subForum ? sampleData.subForum : ''],
        href: sampleData.subForum
          ? router(routesPath.exponentSampleDataSubForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'exhibitorRef',
        value: '4. Référence exposant',
        resume: [sampleData.exhibitorRef ? sampleData.exhibitorRef : ''],
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataExhibitorRef, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'productDestination',
        value: '5. Destination produit',
        resume: [
          Array.isArray(sampleData.productDestination)
            ? sampleData.productDestination.join(' - ')
            : sampleData.productDestination || '',
        ],
        href: sampleData.productDestination
          ? router(routesPath.exponentSampleDataProductDestination, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'animal',
        value: '6. Animal',
        resume: [
          Array.isArray(sampleData.animal)
            ? sampleData.animal.join(' - ')
            : sampleData.animal || '',
        ],
        href: sampleData.animal
          ? router(routesPath.exponentSampleDataAnimal, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'tanningType',
        value: '7. Type de tannage',
        resume: [
          Array.isArray(sampleData.tanningType)
            ? sampleData.tanningType.join(' - ')
            : sampleData.tanningType || '',
        ],
        href: sampleData.tanningType
          ? router(routesPath.exponentSampleDataTanningType, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'aspect',
        value: '8. Aspect',
        resume: [
          Array.isArray(sampleData.aspect)
            ? sampleData.aspect.join(' - ')
            : sampleData.aspect || '',
        ],
        href: sampleData.aspect
          ? router(routesPath.exponentSampleDataAspect, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'performanceCode',
        value: '9. Codes de performances',
        resume: [
          Array.isArray(sampleData.performanceCode)
            ? sampleData.performanceCode.join(' - ')
            : sampleData.performanceCode || '',
        ],
        href: sampleData.performanceCode
          ? router(routesPath.exponentSampleDataPerformanceCode, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'certifications',
        value: '10. Certifications',
        resume: [
          Array.isArray(sampleData.certifications)
            ? sampleData.certifications.join(' - ')
            : sampleData.certifications || '',
        ],
        href: sampleData.certifications
          ? router(routesPath.exponentSampleDataCertifications, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'mkpForum',
        value: '11. Présélection PV',
        resume: [sampleData.mkpForum ? sampleData.mkpForum : ''],
        href: sampleData.mkpForum
          ? router(routesPath.exponentSampleDataMkpForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'shooting',
        value: '12. INFO shooting',
        resume: [
          Array.isArray(sampleData.shooting)
            ? sampleData.shooting.join(' - ')
            : sampleData.shooting || '',
        ],
        href: sampleData.shooting
          ? router(routesPath.exponentSampleDataShooting, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'boxe',
        value: '13. Caisse de rangement',
        resume: [sampleData.boxe ? sampleData.boxe : ''],
        href: sampleData.boxe
          ? router(routesPath.exponentSampleDataBoxes, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'label',
        value: '14. Etiquette',
        resume: [sampleData.label ? 'Photo' : ''],
        href: sampleData.label
          ? router(routesPath.exponentSampleDataLabel, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'summary',
        value: '15. Récapitulatif',
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataSummary, { exposantId, sampleId })
          : undefined,
      },
    ]
    return stepsLeather
  }
  if (sampleData.routeType === 'LeatherSpecial') {
    const stepsLeather = [
      {
        isCurrent: lastSegment === 'category',
        value: '1. Catégories',
        resume: [sampleData.category ? sampleData.category : ''],
        href: sampleData.category
          ? router(routesPath.exponentSampleDataCategory, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'forum',
        value: '2. Forum',
        resume: [sampleData.forum ? sampleData.forum : ''],
        href: sampleData.forum
          ? router(routesPath.exponentSampleDataForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'subForum',
        value: '3. Sous-forum',
        resume: [sampleData.subForum ? sampleData.subForum : ''],
        href: sampleData.subForum
          ? router(routesPath.exponentSampleDataSubForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'exhibitorRef',
        value: '4. Référence exposant',
        resume: [sampleData.exhibitorRef ? sampleData.exhibitorRef : ''],
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataExhibitorRef, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'material',
        value: '5. Composition',
        resume: [
          Array.isArray(sampleData.material)
            ? sampleData.material.join(' - ')
            : sampleData.material || '',
        ],
        href: sampleData.material
          ? router(routesPath.exponentSampleDataMaterial, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'productDestination',
        value: '6. Destination produit',
        resume: [
          Array.isArray(sampleData.productDestination)
            ? sampleData.productDestination.join(' - ')
            : sampleData.productDestination || '',
        ],
        href: sampleData.productDestination
          ? router(routesPath.exponentSampleDataProductDestination, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'pattern',
        value: '7. Motif',
        resume: [
          Array.isArray(sampleData.pattern)
            ? sampleData.pattern.join(' - ')
            : sampleData.pattern || '',
        ],
        href: sampleData.pattern
          ? router(routesPath.exponentSampleDataPattern, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'performanceCode',
        value: '8. Codes de performances',
        resume: [
          Array.isArray(sampleData.performanceCode)
            ? sampleData.performanceCode.join(' - ')
            : sampleData.performanceCode || '',
        ],
        href: sampleData.performanceCode
          ? router(routesPath.exponentSampleDataPerformanceCode, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'certifications',
        value: '9. Certifications',
        resume: [
          Array.isArray(sampleData.certifications)
            ? sampleData.certifications.join(' - ')
            : sampleData.certifications || '',
        ],
        href: sampleData.certifications
          ? router(routesPath.exponentSampleDataCertifications, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'mkpForum',
        value: '10. Présélection PV',
        resume: [sampleData.mkpForum ? sampleData.mkpForum : ''],
        href: sampleData.mkpForum
          ? router(routesPath.exponentSampleDataMkpForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'shooting',
        value: '11. INFO shooting',
        resume: [
          Array.isArray(sampleData.shooting)
            ? sampleData.shooting.join(' - ')
            : sampleData.shooting || '',
        ],
        href: sampleData.shooting
          ? router(routesPath.exponentSampleDataShooting, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'boxe',
        value: '12. Caisse de rangement',
        resume: [sampleData.boxe ? sampleData.boxe : ''],
        href: sampleData.boxe
          ? router(routesPath.exponentSampleDataBoxes, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'label',
        value: '13. Etiquette',
        resume: [sampleData.label ? 'Photo' : ''],
        href: sampleData.label
          ? router(routesPath.exponentSampleDataLabel, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'summary',
        value: '14. Récapitulatif',
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataSummary, { exposantId, sampleId })
          : undefined,
      },
    ]
    return stepsLeather
  }
  if (sampleData.routeType === 'LeatherSpecialMarketplace') {
    const stepsLeather = [
      {
        isCurrent: lastSegment === 'category',
        value: '1. Catégories',
        resume: [sampleData.category ? sampleData.category : ''],
        href: sampleData.category
          ? router(routesPath.exponentSampleDataCategory, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'forum',
        value: '2. Forum',
        resume: [sampleData.forum ? sampleData.forum : ''],
        href: sampleData.forum
          ? router(routesPath.exponentSampleDataForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'subForum',
        value: '3. Sous-forum',
        resume: [sampleData.subForum ? sampleData.subForum : ''],
        href: sampleData.subForum
          ? router(routesPath.exponentSampleDataSubForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'exhibitorRef',
        value: '4. Référence exposant',
        resume: [sampleData.exhibitorRef ? sampleData.exhibitorRef : ''],
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataExhibitorRef, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'material',
        value: '5. Composition',
        resume: [
          Array.isArray(sampleData.material)
            ? sampleData.material.join(' - ')
            : sampleData.material || '',
        ],
        href: sampleData.material
          ? router(routesPath.exponentSampleDataMaterial, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'productDestination',
        value: '6. Destination produit',
        resume: [
          Array.isArray(sampleData.productDestination)
            ? sampleData.productDestination.join(' - ')
            : sampleData.productDestination || '',
        ],
        href: sampleData.productDestination
          ? router(routesPath.exponentSampleDataProductDestination, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'pattern',
        value: '7. Motif',
        resume: [
          Array.isArray(sampleData.pattern)
            ? sampleData.pattern.join(' - ')
            : sampleData.pattern || '',
        ],
        href: sampleData.pattern
          ? router(routesPath.exponentSampleDataPattern, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'performanceCode',
        value: '8. Codes de performances',
        resume: [
          Array.isArray(sampleData.performanceCode)
            ? sampleData.performanceCode.join(' - ')
            : sampleData.performanceCode || '',
        ],
        href: sampleData.performanceCode
          ? router(routesPath.exponentSampleDataPerformanceCode, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'certifications',
        value: '9. Certifications',
        resume: [
          Array.isArray(sampleData.certifications)
            ? sampleData.certifications.join(' - ')
            : sampleData.certifications || '',
        ],
        href: sampleData.certifications
          ? router(routesPath.exponentSampleDataCertifications, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'mkpForum',
        value: '10. Présélection PV',
        resume: [sampleData.mkpForum ? sampleData.mkpForum : ''],
        href: sampleData.mkpForum
          ? router(routesPath.exponentSampleDataMkpForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'shooting',
        value: '11. INFO shooting',
        resume: [
          Array.isArray(sampleData.shooting)
            ? sampleData.shooting.join(' - ')
            : sampleData.shooting || '',
        ],
        href: sampleData.shooting
          ? router(routesPath.exponentSampleDataShooting, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'boxe',
        value: '12. Caisse de rangement',
        resume: [sampleData.boxe ? sampleData.boxe : ''],
        href: sampleData.boxe
          ? router(routesPath.exponentSampleDataBoxes, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'label',
        value: '13. Etiquette',
        resume: [sampleData.label ? 'Photo' : ''],
        href: sampleData.label
          ? router(routesPath.exponentSampleDataLabel, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'summary',
        value: '14. Récapitulatif',
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataSummary, { exposantId, sampleId })
          : undefined,
      },
    ]
    return stepsLeather
  }
  if (sampleData.routeType === 'Accessories') {
    const stepsAccessories = [
      {
        isCurrent: lastSegment === 'category',
        value: '1. Catégories',
        resume: [sampleData.category ? sampleData.category : ''],
        href: sampleData.category
          ? router(routesPath.exponentSampleDataCategory, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'forum',
        value: '2. Forum',
        resume: [sampleData.forum ? sampleData.forum : ''],
        href: sampleData.forum
          ? router(routesPath.exponentSampleDataForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'subForum',
        value: '3. Sous-forum',
        resume: [sampleData.subForum ? sampleData.subForum : ''],
        href: sampleData.subForum
          ? router(routesPath.exponentSampleDataSubForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'exhibitorRef',
        value: '4. Référence exposant',
        resume: [sampleData.exhibitorRef ? sampleData.exhibitorRef : ''],
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataExhibitorRef, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'material',
        value: '5. Composition',
        resume: [
          Array.isArray(sampleData.material)
            ? sampleData.material.join(' - ')
            : sampleData.material || '',
        ],
        href: sampleData.material
          ? router(routesPath.exponentSampleDataMaterial, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'productDestination',
        value: '6. Destination produit',
        resume: [
          Array.isArray(sampleData.productDestination)
            ? sampleData.productDestination.join(' - ')
            : sampleData.productDestination || '',
        ],
        href: sampleData.productDestination
          ? router(routesPath.exponentSampleDataProductDestination, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'accessorieType',
        value: "7. Type d'accessoire",
        resume: [
          Array.isArray(sampleData.accessorieType)
            ? sampleData.accessorieType.join(' - ')
            : sampleData.accessorieType || '',
        ],
        href: sampleData.accessorieType
          ? router(routesPath.exponentSampleDataAccessorieType, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'aspect',
        value: '8. Aspect',
        resume: [
          Array.isArray(sampleData.aspect)
            ? sampleData.aspect.join(' - ')
            : sampleData.aspect || '',
        ],
        href: sampleData.aspect
          ? router(routesPath.exponentSampleDataAspect, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'performanceCode',
        value: '9. Codes de performance',
        resume: [
          Array.isArray(sampleData.performanceCode)
            ? sampleData.performanceCode.join(' - ')
            : sampleData.performanceCode || '',
        ],
        href: sampleData.performanceCode
          ? router(routesPath.exponentSampleDataPerformanceCode, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'certifications',
        value: '10. Certifications',
        resume: [
          Array.isArray(sampleData.certifications)
            ? sampleData.certifications.join(' - ')
            : sampleData.certifications || '',
        ],
        href: sampleData.certifications
          ? router(routesPath.exponentSampleDataCertifications, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'mkpForum',
        value: '11. Présélection PV',
        resume: [sampleData.mkpForum ? sampleData.mkpForum : ''],
        href: sampleData.mkpForum
          ? router(routesPath.exponentSampleDataMkpForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'shooting',
        value: '12. INFO shooting',
        resume: [
          Array.isArray(sampleData.shooting)
            ? sampleData.shooting.join(' - ')
            : sampleData.shooting || '',
        ],
        href: sampleData.shooting
          ? router(routesPath.exponentSampleDataShooting, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'boxe',
        value: '13. Caisse de rangement',
        resume: [sampleData.boxe ? sampleData.boxe : ''],
        href: sampleData.boxe
          ? router(routesPath.exponentSampleDataBoxes, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'label',
        value: '14. Étiquette',
        resume: [sampleData.label ? 'Photo' : ''],
        href: sampleData.label
          ? router(routesPath.exponentSampleDataLabel, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'summary',
        value: '15. récapitulatif',
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataSummary, { exposantId, sampleId })
          : undefined,
      },
    ]
    return stepsAccessories
  }
  if (sampleData.routeType === 'AccessoriesMarketplace') {
    const stepsAccessories = [
      {
        isCurrent: lastSegment === 'category',
        value: '1. Catégories',
        resume: [sampleData.category ? sampleData.category : ''],
        href: sampleData.category
          ? router(routesPath.exponentSampleDataCategory, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'forum',
        value: '2. Forum',
        resume: [sampleData.forum ? sampleData.forum : ''],
        href: sampleData.forum
          ? router(routesPath.exponentSampleDataForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'subForum',
        value: '3. Sous-forum',
        resume: [sampleData.subForum ? sampleData.subForum : ''],
        href: sampleData.subForum
          ? router(routesPath.exponentSampleDataSubForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'exhibitorRef',
        value: '4. Référence exposant',
        resume: [sampleData.exhibitorRef ? sampleData.exhibitorRef : ''],
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataExhibitorRef, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'material',
        value: '5. Composition',
        resume: [
          Array.isArray(sampleData.material)
            ? sampleData.material.join(' - ')
            : sampleData.material || '',
        ],
        href: sampleData.material
          ? router(routesPath.exponentSampleDataMaterial, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'productDestination',
        value: '6. Destination produit',
        resume: [
          Array.isArray(sampleData.productDestination)
            ? sampleData.productDestination.join(' - ')
            : sampleData.productDestination || '',
        ],
        href: sampleData.productDestination
          ? router(routesPath.exponentSampleDataProductDestination, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'accessorieType',
        value: "7. Type d'accessoire",
        resume: [
          Array.isArray(sampleData.accessorieType)
            ? sampleData.accessorieType.join(' - ')
            : sampleData.accessorieType || '',
        ],
        href: sampleData.accessorieType
          ? router(routesPath.exponentSampleDataAccessorieType, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'aspect',
        value: '8. Aspect',
        resume: [
          Array.isArray(sampleData.aspect)
            ? sampleData.aspect.join(' - ')
            : sampleData.aspect || '',
        ],
        href: sampleData.aspect
          ? router(routesPath.exponentSampleDataAspect, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'performanceCode',
        value: '9. Codes de performance',
        resume: [
          Array.isArray(sampleData.performanceCode)
            ? sampleData.performanceCode.join(' - ')
            : sampleData.performanceCode || '',
        ],
        href: sampleData.performanceCode
          ? router(routesPath.exponentSampleDataPerformanceCode, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'certifications',
        value: '10. Certifications',
        resume: [
          Array.isArray(sampleData.certifications)
            ? sampleData.certifications.join(' - ')
            : sampleData.certifications || '',
        ],
        href: sampleData.certifications
          ? router(routesPath.exponentSampleDataCertifications, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'mkpForum',
        value: '11. Présélection PV',
        resume: [sampleData.mkpForum ? sampleData.mkpForum : ''],
        href: sampleData.mkpForum
          ? router(routesPath.exponentSampleDataMkpForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'shooting',
        value: '12. INFO shooting',
        resume: [
          Array.isArray(sampleData.shooting)
            ? sampleData.shooting.join(' - ')
            : sampleData.shooting || '',
        ],
        href: sampleData.shooting
          ? router(routesPath.exponentSampleDataShooting, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'boxe',
        value: '13. Caisse de rangement',
        resume: [sampleData.boxe ? sampleData.boxe : ''],
        href: sampleData.boxe
          ? router(routesPath.exponentSampleDataBoxes, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'label',
        value: '14. Étiquette',
        resume: [sampleData.label ? 'Photo' : ''],
        href: sampleData.label
          ? router(routesPath.exponentSampleDataLabel, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'summary',
        value: '15. Récapitulatif',
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataSummary, { exposantId, sampleId })
          : undefined,
      },
    ]
    return stepsAccessories
  }
  if (sampleData.routeType === 'Manufacturing Leather') {
    const stepsManufacturingLeather = [
      {
        isCurrent: lastSegment === 'category',
        value: '1. Catégories',
        resume: [sampleData.category ? sampleData.category : ''],
        href: sampleData.category
          ? router(routesPath.exponentSampleDataCategory, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'forum',
        value: '2. Forum',
        resume: [sampleData.forum ? sampleData.forum : ''],
        href: sampleData.forum
          ? router(routesPath.exponentSampleDataForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'subForum',
        value: '3. Sous-forum',
        resume: [sampleData.subForum ? sampleData.subForum : ''],
        href: sampleData.subForum
          ? router(routesPath.exponentSampleDataSubForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'exhibitorRef',
        value: '4. Référence',
        resume: [sampleData.exhibitorRef ? sampleData.exhibitorRef : ''],
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataExhibitorRef, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'performanceCode',
        value: '5. Codes de performances',
        resume: [
          Array.isArray(sampleData.performanceCode)
            ? sampleData.performanceCode.join(' - ')
            : sampleData.performanceCode || '',
        ],
        href: sampleData.performanceCode
          ? router(routesPath.exponentSampleDataPerformanceCode, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'certifications',
        value: '6. Certifications',
        resume: [
          Array.isArray(sampleData.certifications)
            ? sampleData.certifications.join(' - ')
            : sampleData.certifications || '',
        ],
        href: sampleData.certifications
          ? router(routesPath.exponentSampleDataCertifications, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'mkpForum',
        value: '7. Présélection PV',
        resume: [sampleData.mkpForum ? sampleData.mkpForum : ''],
        href: sampleData.mkpForum
          ? router(routesPath.exponentSampleDataMkpForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'summary',
        value: '8. Récapitulatif',
        href: sampleData.mkpForum
          ? router(routesPath.exponentSampleDataSummary, { exposantId, sampleId })
          : undefined,
      },
    ]
    return stepsManufacturingLeather
  }
  if (sampleData.routeType === 'Manufacturing LeatherMarketplace') {
    const stepsManufacturingLeather = [
      {
        isCurrent: lastSegment === 'category',
        value: '1. Catégories',
        resume: [sampleData.category ? sampleData.category : ''],
        href: sampleData.category
          ? router(routesPath.exponentSampleDataCategory, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'forum',
        value: '2. Forum',
        resume: [sampleData.forum ? sampleData.forum : ''],
        href: sampleData.forum
          ? router(routesPath.exponentSampleDataForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'subForum',
        value: '3. Sous-forum',
        resume: [sampleData.subForum ? sampleData.subForum : ''],
        href: sampleData.subForum
          ? router(routesPath.exponentSampleDataSubForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'exhibitorRef',
        value: '4. Référence',
        resume: [sampleData.exhibitorRef ? sampleData.exhibitorRef : ''],
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataExhibitorRef, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'material',
        value: '5. Composition',
        resume: [
          Array.isArray(sampleData.material)
            ? sampleData.material.join(' - ')
            : sampleData.material || '',
        ],
        href: sampleData.material
          ? router(routesPath.exponentSampleDataMaterial, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'performanceCode',
        value: '6. Codes de performances',
        resume: [
          Array.isArray(sampleData.performanceCode)
            ? sampleData.performanceCode.join(' - ')
            : sampleData.performanceCode || '',
        ],
        href: sampleData.performanceCode
          ? router(routesPath.exponentSampleDataPerformanceCode, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'certifications',
        value: '7. Certifications',
        resume: [
          Array.isArray(sampleData.certifications)
            ? sampleData.certifications.join(' - ')
            : sampleData.certifications || '',
        ],
        href: sampleData.certifications
          ? router(routesPath.exponentSampleDataCertifications, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'mkpForum',
        value: '8. Présélection PV',
        resume: [sampleData.mkpForum ? sampleData.mkpForum : ''],
        href: sampleData.mkpForum
          ? router(routesPath.exponentSampleDataMkpForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'label',
        value: '9. Étiquette',
        resume: [sampleData.label ? 'Photo' : ''],
        href: sampleData.label
          ? router(routesPath.exponentSampleDataLabel, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'manufacturingType',
        value: '10. Type de produit',
        resume: [sampleData.manufacturingType ? sampleData.manufacturingType : ''],
        href: sampleData.manufacturingType
          ? router(routesPath.exponentSampleDataManufacturingType, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'summary',
        value: '11. Récapitulatif',
        href: sampleData.manufacturingType
          ? router(routesPath.exponentSampleDataSummary, { exposantId, sampleId })
          : undefined,
      },
    ]
    return stepsManufacturingLeather
  }
  if (sampleData.routeType === 'Manufacturing') {
    const stepsManufacturing = [
      {
        isCurrent: lastSegment === 'category',
        value: '1. Catégories',
        resume: [sampleData.category ? sampleData.category : ''],
        href: sampleData.category
          ? router(routesPath.exponentSampleDataCategory, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'forum',
        value: '2. Forum',
        resume: [sampleData.forum ? sampleData.forum : ''],
        href: sampleData.forum
          ? router(routesPath.exponentSampleDataForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'subForum',
        value: '3. Sous-forum',
        resume: [sampleData.subForum ? sampleData.subForum : ''],
        href: sampleData.subForum
          ? router(routesPath.exponentSampleDataSubForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'exhibitorRef',
        value: '4. Référence',
        resume: [sampleData.exhibitorRef ? sampleData.exhibitorRef : ''],
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataExhibitorRef, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'performanceCode',
        value: '5. Codes de performances',
        resume: [
          Array.isArray(sampleData.performanceCode)
            ? sampleData.performanceCode.join(' - ')
            : sampleData.performanceCode || '',
        ],
        href: sampleData.performanceCode
          ? router(routesPath.exponentSampleDataPerformanceCode, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'certifications',
        value: '6. Certifications',
        resume: [
          Array.isArray(sampleData.certifications)
            ? sampleData.certifications.join(' - ')
            : sampleData.certifications || '',
        ],
        href: sampleData.certifications
          ? router(routesPath.exponentSampleDataCertifications, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'mkpForum',
        value: '7. Présélection PV',
        resume: [sampleData.mkpForum ? sampleData.mkpForum : ''],
        href: sampleData.mkpForum
          ? router(routesPath.exponentSampleDataMkpForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'summary',
        value: '8. Récapitulatif',
        href: sampleData.mkpForum
          ? router(routesPath.exponentSampleDataSummary, { exposantId, sampleId })
          : undefined,
      },
    ]
    return stepsManufacturing
  }
  if (sampleData.routeType === 'ManufacturingMarketplace') {
    const stepsManufacturingMarketplace = [
      {
        isCurrent: lastSegment === 'category',
        value: '1. Catégories',
        resume: [sampleData.category ? sampleData.category : ''],
        href: sampleData.category
          ? router(routesPath.exponentSampleDataCategory, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'forum',
        value: '2. Forum',
        resume: [sampleData.forum ? sampleData.forum : ''],
        href: sampleData.forum
          ? router(routesPath.exponentSampleDataForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'subForum',
        value: '3. Sous-forum',
        resume: [sampleData.subForum ? sampleData.subForum : ''],
        href: sampleData.subForum
          ? router(routesPath.exponentSampleDataSubForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'exhibitorRef',
        value: '4. Référence',
        resume: [sampleData.exhibitorRef ? sampleData.exhibitorRef : ''],
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataExhibitorRef, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'material',
        value: '5. Composition',
        resume: [
          Array.isArray(sampleData.material)
            ? sampleData.material.join(' - ')
            : sampleData.material || '',
        ],
        href: sampleData.material
          ? router(routesPath.exponentSampleDataMaterial, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'performanceCode',
        value: '6. Codes de performances',
        resume: [
          Array.isArray(sampleData.performanceCode)
            ? sampleData.performanceCode.join(' - ')
            : sampleData.performanceCode || '',
        ],
        href: sampleData.performanceCode
          ? router(routesPath.exponentSampleDataPerformanceCode, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'certifications',
        value: '7. Certifications',
        resume: [
          Array.isArray(sampleData.certifications)
            ? sampleData.certifications.join(' - ')
            : sampleData.certifications || '',
        ],
        href: sampleData.certifications
          ? router(routesPath.exponentSampleDataCertifications, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'mkpForum',
        value: '8. Présélection PV',
        resume: [sampleData.mkpForum ? sampleData.mkpForum : ''],
        href: sampleData.mkpForum
          ? router(routesPath.exponentSampleDataMkpForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'label',
        value: '9. Étiquette',
        resume: [sampleData.label ? 'Photo' : ''],
        href: sampleData.label
          ? router(routesPath.exponentSampleDataLabel, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'manufacturingType',
        value: '10. Type de produit',
        resume: [sampleData.manufacturingType ? sampleData.manufacturingType : ''],
        href: sampleData.manufacturingType
          ? router(routesPath.exponentSampleDataManufacturingType, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'summary',
        value: '11. Récapitulatif',
        href: sampleData.manufacturingType
          ? router(routesPath.exponentSampleDataSummary, { exposantId, sampleId })
          : undefined,
      },
    ]
    return stepsManufacturingMarketplace
  }
  if (sampleData.routeType === 'Yarns') {
    const stepsYarns = [
      {
        isCurrent: lastSegment === 'category',
        value: '1. Catégories',
        resume: [sampleData.category ? sampleData.category : ''],
        href: sampleData.category
          ? router(routesPath.exponentSampleDataCategory, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'forum',
        value: '2. Forum',
        resume: [sampleData.forum ? sampleData.forum : ''],
        href: sampleData.forum
          ? router(routesPath.exponentSampleDataForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'subForum',
        value: '3. Sous-forum',
        resume: [sampleData.subForum ? sampleData.subForum : ''],
        href: sampleData.subForum
          ? router(routesPath.exponentSampleDataSubForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'exhibitorRef',
        value: '4. Référence',
        resume: [sampleData.exhibitorRef ? sampleData.exhibitorRef : ''],
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataExhibitorRef, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'productDestination',
        value: '5. Destination produit',
        resume: [
          Array.isArray(sampleData.productDestination)
            ? sampleData.productDestination.join(' - ')
            : sampleData.productDestination || '',
        ],
        href: sampleData.productDestination
          ? router(routesPath.exponentSampleDataProductDestination, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'unitValue',
        value: '6. Unit Value',
        resume: [
          Array.isArray(sampleData.unitValue)
            ? sampleData.unitValue.join(' - ')
            : sampleData.unitValue || '',
        ],
        href: sampleData.unitValue
          ? router(routesPath.exponentSampleDataUnitValue, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'numberEnd',
        value: '7. Number End',
        resume: [
          Array.isArray(sampleData.numberEnd)
            ? sampleData.numberEnd.join(' - ')
            : sampleData.numberEnd || '',
        ],
        href: sampleData.numberEnd
          ? router(routesPath.exponentSampleDataNumberEnd, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'aspect',
        value: '8. Aspect',
        resume: [
          Array.isArray(sampleData.aspect)
            ? sampleData.aspect.join(' - ')
            : sampleData.aspect || '',
        ],
        href: sampleData.aspect
          ? router(routesPath.exponentSampleDataAspect, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'technologyActivity',
        value: '9. Technology Activity',
        resume: [
          Array.isArray(sampleData.technologyActivity)
            ? sampleData.technologyActivity.join(' - ')
            : sampleData.technologyActivity || '',
        ],
        href: sampleData.technologyActivity
          ? router(routesPath.exponentSampleDataTechnologyActivity, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'performanceCode',
        value: '10. Codes de performances',
        resume: [
          Array.isArray(sampleData.performanceCode)
            ? sampleData.performanceCode.join(' - ')
            : sampleData.performanceCode || '',
        ],
        href: sampleData.performanceCode
          ? router(routesPath.exponentSampleDataPerformanceCode, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'certifications',
        value: '11. Certifications',
        resume: [
          Array.isArray(sampleData.certifications)
            ? sampleData.certifications.join(' - ')
            : sampleData.certifications || '',
        ],
        href: sampleData.certifications
          ? router(routesPath.exponentSampleDataCertifications, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'shooting',
        value: '12. INFO shooting',
        resume: [
          Array.isArray(sampleData.shooting)
            ? sampleData.shooting.join(' - ')
            : sampleData.shooting || '',
        ],
        href: sampleData.shooting
          ? router(routesPath.exponentSampleDataShooting, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'boxe',
        value: '13. Caisse de rangement',
        resume: [sampleData.boxe ? sampleData.boxe : ''],
        href: sampleData.boxe
          ? router(routesPath.exponentSampleDataBoxes, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'summary',
        value: '14. Récapitulatif',
        href: sampleData.boxe
          ? router(routesPath.exponentSampleDataSummary, { exposantId, sampleId })
          : undefined,
      },
    ]
    return stepsYarns
  }
  if (sampleData.routeType === 'YarnsMarketplace') {
    const stepsYarnsMarketplace = [
      {
        isCurrent: lastSegment === 'category',
        value: '1. Catégories',
        resume: [sampleData.category ? sampleData.category : ''],
        href: sampleData.category
          ? router(routesPath.exponentSampleDataCategory, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'forum',
        value: '2. Forum',
        resume: [sampleData.forum ? sampleData.forum : ''],
        href: sampleData.forum
          ? router(routesPath.exponentSampleDataForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'subForum',
        value: '3. Sous-forum',
        resume: [sampleData.subForum ? sampleData.subForum : ''],
        href: sampleData.subForum
          ? router(routesPath.exponentSampleDataSubForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'exhibitorRef',
        value: '4. Référence',
        resume: [sampleData.exhibitorRef ? sampleData.exhibitorRef : ''],
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataExhibitorRef, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'material',
        value: '5. Composition',
        resume: [
          Array.isArray(sampleData.material)
            ? sampleData.material.join(' - ')
            : sampleData.material || '',
        ],
        href: sampleData.material
          ? router(routesPath.exponentSampleDataMaterial, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'productDestination',
        value: '6. Destination produit',
        resume: [
          Array.isArray(sampleData.productDestination)
            ? sampleData.productDestination.join(' - ')
            : sampleData.productDestination || '',
        ],
        href: sampleData.productDestination
          ? router(routesPath.exponentSampleDataProductDestination, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'unitValue',
        value: '7. Unit Value',
        resume: [
          Array.isArray(sampleData.unitValue)
            ? sampleData.unitValue.join(' - ')
            : sampleData.unitValue || '',
        ],
        href: sampleData.unitValue
          ? router(routesPath.exponentSampleDataUnitValue, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'numberEnd',
        value: '8. Number End',
        resume: [
          Array.isArray(sampleData.numberEnd)
            ? sampleData.numberEnd.join(' - ')
            : sampleData.numberEnd || '',
        ],
        href: sampleData.numberEnd
          ? router(routesPath.exponentSampleDataNumberEnd, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'aspect',
        value: '9. Aspect',
        resume: [
          Array.isArray(sampleData.aspect)
            ? sampleData.aspect.join(' - ')
            : sampleData.aspect || '',
        ],
        href: sampleData.aspect
          ? router(routesPath.exponentSampleDataAspect, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'technologyActivity',
        value: '10. Technology Activity',
        resume: [
          Array.isArray(sampleData.technologyActivity)
            ? sampleData.technologyActivity.join(' - ')
            : sampleData.technologyActivity || '',
        ],
        href: sampleData.technologyActivity
          ? router(routesPath.exponentSampleDataTechnologyActivity, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'performanceCode',
        value: '11. Codes de performances',
        resume: [
          Array.isArray(sampleData.performanceCode)
            ? sampleData.performanceCode.join(' - ')
            : sampleData.performanceCode || '',
        ],
        href: sampleData.performanceCode
          ? router(routesPath.exponentSampleDataPerformanceCode, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'certifications',
        value: '12. Certifications',
        resume: [
          Array.isArray(sampleData.certifications)
            ? sampleData.certifications.join(' - ')
            : sampleData.certifications || '',
        ],
        href: sampleData.certifications
          ? router(routesPath.exponentSampleDataCertifications, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'mkpForum',
        value: '13. Présélection PV',
        resume: [sampleData.mkpForum ? sampleData.mkpForum : ''],
        href: sampleData.mkpForum
          ? router(routesPath.exponentSampleDataMkpForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'shooting',
        value: '14. INFO shooting',
        resume: [
          Array.isArray(sampleData.shooting)
            ? sampleData.shooting.join(' - ')
            : sampleData.shooting || '',
        ],
        href: sampleData.shooting
          ? router(routesPath.exponentSampleDataShooting, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'boxe',
        value: '15. Caisse de rangement',
        resume: [sampleData.boxe ? sampleData.boxe : ''],
        href: sampleData.boxe
          ? router(routesPath.exponentSampleDataBoxes, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'label',
        value: '16. Etiquette',
        resume: [sampleData.label ? 'Photo' : ''],
        href: sampleData.label
          ? router(routesPath.exponentSampleDataLabel, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'color',
        value: '17. Couleur',
        resume: [sampleData.color ? sampleData.color : ''],
        href: sampleData.color
          ? router(routesPath.exponentSampleDataColor, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'summary',
        value: '18. Récapitulatif',
        href: sampleData.color
          ? router(routesPath.exponentSampleDataSummary, { exposantId, sampleId })
          : undefined,
      },
    ]
    return stepsYarnsMarketplace
  }
  if (sampleData.routeType === 'Designs') {
    const stepsDesign = [
      {
        isCurrent: lastSegment === 'category',
        value: '1. Catégories',
        resume: [sampleData.category ? sampleData.category : ''],
        href: sampleData.category
          ? router(routesPath.exponentSampleDataCategory, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'forum',
        value: '2. Forum',
        resume: [sampleData.forum ? sampleData.forum : ''],
        href: sampleData.forum
          ? router(routesPath.exponentSampleDataForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'subForum',
        value: '3. Sous-forum',
        resume: [sampleData.subForum ? sampleData.subForum : ''],
        href: sampleData.subForum
          ? router(routesPath.exponentSampleDataSubForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'exhibitorRef',
        value: '4. Référence',
        resume: [sampleData.exhibitorRef ? sampleData.exhibitorRef : ''],
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataExhibitorRef, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'pattern',
        value: '5. Motif',
        resume: [sampleData.pattern ? sampleData.pattern : ''],
        href: sampleData.pattern
          ? router(routesPath.exponentSampleDataPattern, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'patternType',
        value: '6. Type de motif',
        resume: [sampleData.patternType ? sampleData.patternType : ''],
        href: sampleData.patternType
          ? router(routesPath.exponentSampleDataPatternType, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'mkpForum',
        value: '7. Présélection PV',
        resume: [sampleData.mkpForum ? sampleData.mkpForum : ''],
        href: sampleData.mkpForum
          ? router(routesPath.exponentSampleDataMkpForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'summary',
        value: '8. Récapitulatif',
        href: sampleData.mkpForum
          ? router(routesPath.exponentSampleDataSummary, { exposantId, sampleId })
          : undefined,
      },
    ]
    return stepsDesign
  }
  if (sampleData.routeType === 'DesignsMarketplace') {
    const stepsDesign = [
      {
        isCurrent: lastSegment === 'category',
        value: '1. Catégories',
        resume: [sampleData.category ? sampleData.category : ''],
        href: sampleData.category
          ? router(routesPath.exponentSampleDataCategory, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'forum',
        value: '2. Forum',
        resume: [sampleData.forum ? sampleData.forum : ''],
        href: sampleData.forum
          ? router(routesPath.exponentSampleDataForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'subForum',
        value: '3. Sous-forum',
        resume: [sampleData.subForum ? sampleData.subForum : ''],
        href: sampleData.subForum
          ? router(routesPath.exponentSampleDataSubForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'exhibitorRef',
        value: '4. Référence',
        resume: [sampleData.exhibitorRef ? sampleData.exhibitorRef : ''],
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataExhibitorRef, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'pattern',
        value: '5. Motif',
        resume: [sampleData.pattern ? sampleData.pattern : ''],
        href: sampleData.pattern
          ? router(routesPath.exponentSampleDataPattern, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'patternType',
        value: '6. Type de motif',
        resume: [sampleData.patternType ? sampleData.patternType : ''],
        href: sampleData.patternType
          ? router(routesPath.exponentSampleDataPatternType, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'mkpForum',
        value: '7. Présélection PV',
        resume: [sampleData.mkpForum ? sampleData.mkpForum : ''],
        href: sampleData.mkpForum
          ? router(routesPath.exponentSampleDataMkpForum, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'label',
        value: '8. Etiquette',
        resume: [sampleData.label ? 'Photo' : ''],
        href: sampleData.label
          ? router(routesPath.exponentSampleDataLabel, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'summary',
        value: '9. Récapitulatif',
        href: sampleData.label
          ? router(routesPath.exponentSampleDataSummary, { exposantId, sampleId })
          : undefined,
      },
    ]
    return stepsDesign
  }
  if (sampleData.routeType === 'Trainee') {
    const stepsTrainee = [
      {
        isCurrent: lastSegment === 'label',
        value: '1. Etiquette',
        resume: [sampleData.label ? 'Photo' : ''],
        href: sampleData.label
          ? router(routesPath.exponentSampleDataLabel, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'exhibitorRef',
        value: '2. Référence',
        resume: [sampleData.exhibitorRef ? sampleData.exhibitorRef : ''],
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataExhibitorRef, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'material',
        value: '3. Composition',
        resume: [
          Array.isArray(sampleData.material)
            ? sampleData.material.join(' - ')
            : sampleData.material || '',
        ],
        href: sampleData.material
          ? router(routesPath.exponentSampleDataMaterial, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'color',
        value: '4. Couleur',
        resume: [sampleData.color ? sampleData.color : ''],
        href: sampleData.color
          ? router(routesPath.exponentSampleDataColor, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'summary',
        value: '5. récapitulatif',
        href: sampleData.color
          ? router(routesPath.exponentSampleDataSummary, { exposantId, sampleId })
          : undefined,
      },
    ]
    return stepsTrainee
  }

  if (sampleData.routeType === 'TraineeShort') {
    const stepsTrainee = [
      {
        isCurrent: lastSegment === 'label',
        value: '1. Etiquette',
        resume: [sampleData.label ? 'Photo' : ''],
        href: sampleData.label
          ? router(routesPath.exponentSampleDataLabel, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'exhibitorRef',
        value: '2. Référence',
        resume: [sampleData.exhibitorRef ? sampleData.exhibitorRef : ''],
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataExhibitorRef, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'summary',
        value: '3. récapitulatif',
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataSummary, { exposantId, sampleId })
          : undefined,
      },
    ]
    return stepsTrainee
  }

  if (sampleData.routeType === 'TraineeDesign') {
    const stepsTrainee = [
      {
        isCurrent: lastSegment === 'exhibitorRef',
        value: '1. Référence',
        resume: [sampleData.exhibitorRef ? sampleData.exhibitorRef : ''],
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataExhibitorRef, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'summary',
        value: '2. récapitulatif',
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataSummary, { exposantId, sampleId })
          : undefined,
      },
    ]
    return stepsTrainee
  }

  if (sampleData.routeType === 'TraineeManuf') {
    const stepsTrainee = [
      {
        isCurrent: lastSegment === 'label',
        value: '1. Etiquette',
        resume: [sampleData.label ? 'Photo' : ''],
        href: sampleData.label
          ? router(routesPath.exponentSampleDataLabel, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'exhibitorRef',
        value: '2. Référence',
        resume: [sampleData.exhibitorRef ? sampleData.exhibitorRef : ''],
        href: sampleData.exhibitorRef
          ? router(routesPath.exponentSampleDataExhibitorRef, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'material',
        value: '3. Composition',
        resume: [
          Array.isArray(sampleData.material)
            ? sampleData.material.join(' - ')
            : sampleData.material || '',
        ],
        href: sampleData.material
          ? router(routesPath.exponentSampleDataMaterial, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'manufacturingType',
        value: '4. Type de produit',
        resume: [sampleData.manufacturingType ? sampleData.manufacturingType : ''],
        href: sampleData.manufacturingType
          ? router(routesPath.exponentSampleDataManufacturingType, { exposantId, sampleId })
          : undefined,
      },
      {
        isCurrent: lastSegment === 'summary',
        value: '5. récapitulatif',
        href: sampleData.manufacturingType
          ? router(routesPath.exponentSampleDataSummary, { exposantId, sampleId })
          : undefined,
      },
    ]
    return stepsTrainee
  } else return []
}
