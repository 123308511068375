import React, { useCallback, useEffect, useState } from 'react'
import { RouteProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import SampleDataTemplate from '../../../templates/SampleData'
import { actions, selectors } from '../../../redux'
import { usePropsSampleDataBoxes } from '../../../templates/SampleData/usePropsSampleDataBoxes'
import { ForumBox, ForumBoxItem } from '../../../graphql/generated/graphql'

const ExponentSampleDataBoxes: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const [defaultBox, setDefaultBox] = useState<ForumBoxItem | undefined>(undefined)
  const collection = useSelector(selectors.exhibitors.collection)
  const form = useSelector(selectors.samples.sampleFormByUniverse)
  const boxes = useSelector(selectors.boxes.forumBoxes)
  const config = useSelector(selectors.configuration.configurationInfo)
  const sample = useSelector(selectors.samples.sampleData)
  const sampleData = useSelector(selectors.samples.sampleData)
  const sampleDataAllInfo = useSelector(selectors.samples.sampleDataAllInfo)

  const findForumBoxBySubForumName = (forumBoxes: ForumBox[], subForumName: string) => {
    return forumBoxes.find((box) => {
      if (Array.isArray(box.subForumBoxes)) {
        return box.subForumBoxes.some((subBox) => subBox?.name === subForumName)
      }
      return false
    })
  }

  const getDefaultBox = useCallback((): ForumBoxItem | undefined => {
    if (sampleData.subForum && boxes) {
      const forumBox = findForumBoxBySubForumName(boxes, sampleData.subForum)
      if (forumBox && Array.isArray(forumBox.subForumBoxes) && forumBox.subForumBoxes.length > 0) {
        const matchingSubForumBox = forumBox.subForumBoxes.find(
          (subForumBox) => subForumBox?.name === sampleData.subForum
        )

        if (matchingSubForumBox) {
          return matchingSubForumBox
        }
      }
    } else if (!sampleData.subForum && boxes)
      return {
        forumId: '0',
        id: '0',
        name: 'Pas de boîte de rangement',
      } as ForumBoxItem
  }, [sampleData, boxes])

  console.log(getDefaultBox())

  useEffect(() => {
    if (sampleDataAllInfo.boxe.items) {
      setDefaultBox(sampleDataAllInfo.boxe.items)
    } else {
      setDefaultBox(getDefaultBox())
    }
  }, [getDefaultBox, sampleDataAllInfo.boxe.items])

  useEffect(() => {
    if (collection || sample) {
      config &&
        dispatch(
          actions.boxes.getForumBoxesRequest({
            exhibitionApiId: collection?.exhibition.apiId as string,
            q: '',
          })
        )
    }
  }, [collection, config, dispatch, sample])

  const templateProps = usePropsSampleDataBoxes(
    defaultBox,
    'Caisses de rangement',
    form.find((item) => item.miraklRef === 'NON_MARKETPLACE_box'),
    boxes
  )

  return defaultBox !== undefined ? <SampleDataTemplate {...templateProps} /> : null
}

export default ExponentSampleDataBoxes
